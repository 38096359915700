{
  "login": "Iniciar sesión",
  "user": "Usuario",
  "password": "Contraseña",
  "enter": "Ingresar",
  "recover": "Recuperar credenciales",
  "info_card": "Para soporte sobre la aplicación SmartEco por favor enviar un correo a",
  "user_not_valid": "Porfavor ingresa un usuario valido!",
  "user_password_valid": "Por favor ingresa una clave valida",
  "password_size": "La clave debe tener almenos 12 caracteres",
  "recovery_options": "Opciones de recuperación",
  "form": "Formulario",
  "recover_user": "Recuperar usuario",
  "recover_password": "Recuperar contraseña",
  "cancel": "Cancelar",
  "label_email": "Correo electrónico",
  "send_email": "Enviar correo",
  "recover_password_capital_letter": "RECUPERAR CONTRASEÑA",
  "name_user_capital_letter": "Nombre de usuario",
  "field_required": "Este campo es obligatorio",
  "new_password_capital_letter": "NUEVA CONTRASEÑA",
  "recover_password_text": "Recuerde que la contraseña debe tener al menos 12 caracteres",
  "very_weak": "Muy débil",
  "weak": "Débil",
  "good": "Buena",
  "strong": "Fuerte",
  "verificate_password_capital_letter": "VERIFICAR CONTRASEÑA",
  "update_password": "Cambiar contraseña",
  "all_rights_reserved": "Todos los derechos reservados",
  "version": "versión",
  "updatePassword": "Contraseña Modificada",
  "error_message1": "Oupss ha ocurrido un problema",
  "equal_fields": "Los campos deben coincidir",
  "shortcut": "ATAJOS",
  "msgIdiom": "Idioma cambiada correctamente",
  "language": "Lenguaje",
  "spanish": "Español",
  "english": "Ingles",
  "Portuguese": "Portugués",
  "SignOff": "Cerrar Sesion",
  "management": "ADMINISTRACION",
  "pane": "Panel de herramientas",
  "viewShortcuts": "Ver atajos de teclado",
  "closeShortcuts": "Cerrar atajos de teclado",
  "beginning": "Inicio",
  "updateTable": "Actualizar tabla",
  "point": "Puntos",
  "account": "Cuenta",
  "groups": "Grupos",
  "domains": "Dominios",
  "doamin": "dominio",
  "rates": "Tarifa",
  "transactions": "Transacciones",
  "serviceProvider": "Prestador de servicio",
  "entities": "Entidades",
  "paymentMethods": "Métodos de pago",
  "money": "Fondos",
  "ResourceSources": "Orígenes de recursos",
  "budget": "Presupuesto",
  "bill": "Factura",
  "Payments": "Pagos",
  "audits": "Auditorías",
  "loads": "Cargas",
  "meter": "Medidores",
  "search": "Buscador",
  "reportDownloads": "Descargas de reportes",
  "company": "Compañía",
  "roles": "Roles",
  "invoiceDashboard": "Dashboard facturas",
  "dashboardPayments ": "Dashboard pagos",
  "Vertebots": "Vertebots",
  "serviceProviders": "Prestadores de Servicios",
  "concepts": "Conceptos",
  "name": "Nombre",
  "copy": "Copiar",
  "copied": "Copiado",
  "abbreviation": "Abreviación",
  "abb": "Abr",
  "type": "Tipo",
  "identificationBusiness": "NIT",
  "dv": "DV",
  "service": "Servicios",
  "movii": "Movii",
  "voucher": "Comprobante",
  "email": "email",
  "yes": "Si",
  "no": "No",
  "delete": "Eliminar",
  "edit": "Editar",
  "create": "Crear",
  "searchs": "Buscar",
  "state": "Estado",
  "activate": "Activar",
  "deactivate": "Desactivar",
  "activeTable": "Activo",
  "inactive": "Inactivo",
  "subproject": "Subproyecto",
  "extras": "Extras",
  "seeExtras": "Ver Extras",
  "extrasOf": "EXTRAS DE ",
  "addEquivalence": "Agregar equivalencia",
  "department": "Departamento",
  "city": "Ciudad",
  "dateFirst": "Fecha de inicio",
  "dateEnd": "Fecha final",
  "total": "Total",
  "record": "Archivos",
  "detail": "Detalle",
  "remember": "Recuerde",
  "textAlertProvider": "Para poder administrar las notificaciones, la cuenta debe ser editada y seleccionar la opción de multi notificación.",
  "deleteTextAlertProvider": "El prestador de servicio ha sido eliminado con éxito.",
  "upateTextAlertProvider": "Prestador de servicio actualizado a:",
  "updateNotificationProviderActivate": "Envio de notificaciones activada",
  "updateNotificationProviderDesactivate": "Envio de notificaciones desactivada",
  "manageNotifications": "Administrar notificaciones",
  "activatePetitionBill": "Activar Envio de petición de factura",
  "deactivatePetitionBill": "Desactivar Envio de petición de factura",
  "viewProviderInformation": "Ver informacion del prestador",
  "close": "Cerrar",
  "short": "Corto",
  "multiNotification": "MULTI NOTIFICACION",
  "phone": "Teléfono",
  "fieldRequiredNumber": "Este campo solo acepta números",
  "fieldRequiredDv": "Debe digitar el numero de digitación",
  "fielRequiredType": "Debe seleccionar un tipo",
  "typeService": "Tipo de Servicio",
  "fieldRequiredTypeService": "Debe seleccionar el tipo de servicio",
  "proffSeding": "ENVIO DE COMPROBANTE",
  "fieldRequiredOption": "Debe seleccionar una opción",
  "notificationEmail": "Notificación por correo",
  "regularPayment": "PAGO REGULAR",
  "regulatedPayment": "Pago regulado",
  "reactiveCharge": "Cobro por reactiva",
  "fieldNitRequired": "Debe digitar un NIT",
  "alertEntityDelete": "La entidad ha sido eliminado con éxito.",
  "nameEntity": "Nombre de la entidad",
  "stateEnity": "Estado de la entidad",
  "notificationDeleteDomains": "El dominio ha sido eliminado con éxito.",
  "father": "Padre",
  "dNotHave": "No tiene",
  "domainCreate": "Dominio creado",
  "alertDomainCreate": "Su Dominio fue creado con exito",
  "selectionFather": "Debe seleccionar un padre",
  "add": "Agregar",
  "removeFather": "remover padre",
  "alertPaymentDelete": "El metodo de pago ha sido eliminado con éxito.",
  "addEquivalenceA": "Agregar equivalencia a",
  "updateConcept": "Editar concepto",
  "alertDeleteMeter": "Esta seguro que desea borrar este medidor?",
  "deleteMeter": "Medidor eliminado correctamente",
  "updateMeters": "Medidor modificada correctamente",
  "addMeterNews": "Medidor creado correctamente",
  "subprojects": "Subproyectos",
  "disableSubProjectText": "Deshabilitar subproyectos en caso de que no aplique para el tipo deservicio",
  "deleteTarFeeAlert": "Esta seguro que desea borrar esta tarifa?",
  "alertDeleteFee": "Tarifa eliminada correctamente",
  "createRate": "Crear tarifa",
  "updateRate": "Editar tarifa",
  "viewFiles": "Ver Archivo",
  "noFiles": "Sin archivos",
  "voltageLevel": "Nivel de voltaje",
  "propiety": "Propiedad",
  "detailOf": "Detalle de",
  "recolection": "Recolección",
  "publicToilet": "Aseo publico",
  "complementary": "Complementario",
  "sumptuary": "Suntuario",
  "energeticValue": "Valor Energético",
  "deleteUserQuestion": "Esta seguro que desea borrar este usuario?",
  "deleteUserAlert": "Usuario eliminado correctamente",
  "updateUserQuestion": "Esta seguro que desea cambiar el estado de este usuario?",
  "updateUserAlert": "Estado de usuario modificado",
  "nickName": "Nickname",
  "lastAdmission": "Ultimo ingreso",
  "verified": "Verificado",
  "notVerified": "Verificación pendiente por el usuario",
  "updateState": "Cambiar estado",
  "assign": "Asignar",
  "access": "Accesos",
  "office": "Oficinas",
  "notification": "Notificaciones",
  "download": "Descargar",
  "accessUpdatedAlert": "Accesos actualizados con exito",
  "group": "Grupo",
  "result": "resultado",
  "userAccessRepeat": "Este usuario tiene todo los grupos asignados",
  "Ops": "Oops",
  "valueRequired": "Este valor es requerido",
  "regionals": "Grupo",
  "typeUser": "Tipo de usuario",
  "allRegionals": "Todas las regionales",
  "selectionAll": "Seleccionar todas",
  "deleteAssign": "Borrar asignaciones",
  "textUserAssgin": "Para continuar por favor seleccione una compañía",
  "assignReportBi": "Asignar Reportes de BI",
  "report": "Reportes",
  "all": "Todos",
  "url": "URL",
  "reportDownload": "Reportes de descarga",
  "assignReportDownload": "Asignacion de reportes",
  "alertOfficeRegister": "Oficinas registaradas correctamente",
  "permissions": "Permisos",
  "removed": "Eliminada",
  "saveChanges": "Guarde para salvar los cambios.",
  "save": "Guardar",
  "alertMessageNotifications": "Todas las notificaciones quedaran desactivadas.",
  "alertConfigNotifications": "Configuraciones de Notificaciones actualizadas",
  "configNotification": "Configurar notificaciones",
  "activateConfirm": "Activada",
  "deactivateConfirm": "Desactivada",
  "messageNotifications": "Se ha desactivado todas las notificaciones de la compañia",
  "notificationBillCompany": "Notificar si la factura de la compañia",
  "wasLoaded": "fue cargada.",
  "notificationVoucherCompany": "Notificar si el comprobante de pago de la compañia",
  "itRejected": "fue rechazada.",
  "notifyIf": "Notificar si",
  "billAndVouchers": "carga factura y/o comprobantes.",
  "addRolSave": "El rol ya fue agregado para esta compañia",
  "userSaveUpdated": "Usuario actualizado correctamente",
  "userGetInto": "Por favor ingresa un nombre!",
  "lastName": "Apellidos",
  "lastNameUsergetInto": "Por favor ingresa un apellido!",
  "emailError": "Porfavor ingresa un correo valido!",
  "rolAplication": "Rol de la applicacion",
  "assignRolCompany": "Asignar roles por compañia",
  "generatePassword": "Generar contraseña",
  "pleasePassworValidate": "Porfavor ingresa una contraseña valida!",
  "users": "Usuarios",
  "rolDeleteAlert": "Esta seguro que desea borrar este rol?",
  "rolDeleteAlertConfirm": "Medidor eliminado correctamente",
  "updateRolAlert": "Esta seguro que desea cambiar el estado de este medidor?",
  "updateRolAlerModif": "Estado de rol modificado",
  "cancelAlert": "Cancelada",
  "actionUpdateCancel": "Acción de cambio de estado cancelada",
  "rolModif": "Rol modificado correctamente",
  "createRol": "Rol creado correctamente",
  "pointDeleteAlert": "El punto ha sido eliminado con éxito.",
  "notVerificed": "",
  "admiPoint": "Administrar punto",
  "viewInfoPoint": "Ver informacion del punto",
  "viewHistoryPoint": "Ver historico de puntos",
  "generateCertificed": "Generar certificado",
  "order": "Orden",
  "direction": "Dirección",
  "others": "Otros",
  "location": "Ubicación",
  "power": "Potencia",
  "amount": "Cantidad",
  "noData": "Sin datos",
  "enterPointName": "Debe escribir un nombre del punto",
  "namePoint": "Nombre del punto",
  "fullName": "Nombre completo",
  "enterCityName": "Debe escribir el nombre de la ciudad",
  "enterDptoName": "Debe escribir el departamento",
  "enterLocationName": "Debe escribir una dirección",
  "paymentNotification": "Notificación de pago",
  "sendNotificationPayment": "Enviar notificación de pago al pago",
  "pointState": "Estado del punto",
  "officeCode": "Código de oficina",
  "entrerCodOffice": "Debe escribir el codigo de la oficina",
  "numberEmployer": "Nº de empleados",
  "area": "Area",
  "latitude": "Latitud",
  "fielObligatoryLatitude": "Este campo es obligatorio si coloco una longitud",
  "length": "Longitud",
  "fieldObligatoryLength": "Este campo es obligatorio si coloco una latitud",
  "namePonitShort": "Debe escribir un nombre corto del punto",
  "updateStateExit": "Se cambio el estado de la cuenta con exito",
  "dateFirstOperation": "Fecha de inicio de operación",
  "dateSelection": "Debe seleccionar una fecha",
  "dateUpdateText": "Fecha de cambio de estado",
  "infoPoint": "Informacion del punto",
  "employer": "Empleados",
  "codOffice": "Codigo de oficina",
  "allowsNotifications": "Permite notificaciones",
  "update": "Cambiar",
  "shedule": "Horarios",
  "enterDate": "Ingrese una fecha",
  "enterDateEnd": "Ingrese una fecha final",
  "accountDelete": "La cuenta ha sido eliminado con éxito.",
  "editAuthorizations": "Editar autorizaciones",
  "UploadArchive": "Subir archivo plano enel",
  "addNovelty": "Agregar novedad",
  "viewNovelty": "Ver novedades",
  "viewInfoAccount": "Ver informacion de la cuenta",
  "viewHistoryAcoount": "Ver historico de cuentas",
  "number": "Número",
  "dates": "Fechas",
  "Cleanliness": "Aseo",
  "water": "Agua",
  "Gas": "Gas",
  "enegy": "Energía",
  "sewerage": "Alcantarillado",
  "streetLighting": "Alumbrado público",
  "novelty": "Novedades",
  "authorized": "Autorizado",
  "paymentInfoCompleted": "Info. pago",
  "newComercio": "Nueva comercializadora desde",
  "previusAccount": "Cuenta anterior",
  "inactiveSince": "Inactivo desde",
  "authorizedPayment": "Pago autorizado",
  "dataAccount": "Datos de la cuenta",
  "codVerification": "Codigo de verificación",
  "enterNumberAccount": "Debe escribir un numero de cuenta",
  "numberMeter": "Numero de medidor",
  "emailNotification": "Correo de notificación",
  "markertType": "Tipo de mercado",
  "infoLocation": "Información de ubicación",
  "selectionRegional": "Debe seleccionar la regional",
  "selectionPoint": "Debe seleccionar un punto",
  "selectionService": "Debe seleccionar el prestador de servicio",
  "dateEmition": "Fecha de emisión",
  "dateStimedPayment": "Fecha estimada de pago",
  "timelyPaymentDate": "Fecha pago oprtuno",
  "initialBillingDate": "Fecha inicial de facturación",
  "dateInitial": "Fecha inicial",
  "BudgetsAndPayments": "Presupuestos y pagos",
  "frequency": "Frecuencia",
  "accountFather": "Cuenta padre",
  "socioeconomicLevel": "Nivel socioeconómico",
  "paymentVertebra": "Pago vertebra",
  "authorizedForPayment": "Autorizado para pago",
  "clientUploadBill": "Cliente sube factura",
  "serviceDate": "Servicios Fechas",
  "StreetLighting": "Alumbrado publico",
  "selectionFrecuency": "Debe seleccionar la frecuencia",
  "uploadFileOk": "Carga de archivo plano subida correctamente",
  "dateBill": "Fecha de la factura",
  "dateReal": "Fecha real",
  "billingPeriod": "Periodo de facturación",
  "dateBillDate": "Fecha de facturación",
  "suspencionDate": "Fecha de suspención",
  "period": "Periodo",
  "upSupport": "Subir soporte",
  "fieldRequired": "Campo requerido!",
  "clickUploadVoucher": "Click o arrastre el comprobante",
  "uploadFile": "Subir archivo",
  "changeOfMarketer": "Cambio de comercializadora",
  "numberAccount": "Numero de cuenta",
  "estimatedDateOFIssue": "Fecha estimada de emisión",
  "tymelyPaymentDate": "Fecha pago oportuno",
  "infoPaymentVertebra": "Información pago vertebra",
  "allowPayment": "Permite pago",
  "typeNovelty": "Tipo de novedad",
  "comentary": "Comentarios",
  "observation": "Observación",
  "comentaryNovelty": "Comentario de la novedad",
  "newNovelty": "Crear novedad",
  "shortName": "Nombre corto",
  "allowPaymentNotificatios": "Permite notificaciones de pago",
  "inactivationDate": "Fecha de inactivación",
  "propertyLevel": "Nivel de propiedad",
  "coordinates": "Coordenadas",
  "dateOfIssue": "Fecha de emisión",
  "numberVerification": "Numero de verificacion",
  "serviceName": "Nombre del servicio",
  "lastBill": "Ultima factura",
  "selectRecordTheChange": "Seleccione un registro para ver los cambios",
  "export": "Exportar",
  "userMakingtheChange": "Usuario que realiza el cambio",
  "operationDate": "Fecha de operación",
  "alertDeleteRates": "Esta seguro que desea borrar esta tarifa?",
  "rateDeleteOk": "Tarifa eliminada correctamente",
  "closeNovelty": "Cerrar novedad",
  "addComentary": "Agregar comentario",
  "deleteGroupAlert": "Grupo eliminado correctamente",
  "groupCode": "Código de grupo",
  "groupName": "Nombre del grupo",
  "errorNameRegional": "Debe escribir un nombre de regional",
  "selectionStateError": "Debe seleccionar un estado",
  "stateGroup": "Estado del grupo",
  "transaction": "Transaccion",
  "resourceSource": "Origen de recurso",
  "BillToPay": "Facturas por pagar",
  "newTransaction": "Crear transacción",
  "closeTransaction": "Cerrar transacción",
  "concilationsView": "Ver conciliaciones",
  "uploadFileConcilation": "Cargar archivo de conciliación",
  "operationType": "Tipo de operación",
  "bankVerification": "Verificado banco",
  "menu": "Menú",
  "transactionVerified": "Se reestablecio la transacción",
  "amounts": "Monto",
  "initialBalance": "Saldo inicial",
  "endBalance": "Saldo final",
  "responsible": "Responsable",
  "reconcile": "Consiliar",
  "viewInfoBill": "Ver información de la factura",
  "detailBill": "Detalle de Factura",
  "noveltyDeleteOk": "Novedad elimnado con exito",
  "comentaryDeleteOk": "Comentatio elimnado con exito",
  "sourceIdResource": "ID Origen del recurso",
  "sourceNameOfTheresource": "Nombre origen del recurso",
  "sourceIdCompany": "ID Origen de la compañia",
  "sourceNameOfTheCompany": "Nombre origen de la compañia",
  "place": "Punto",
  "contactAccount": "Cuenta contrato",
  "marketer": "Comercializadora",
  "numberBill": "Numero de factura",
  "typeRegister": "Tipo de registro",
  "totalToPay": "Total a pagar",
  "paymentMade": "Pago realizado",
  "dateOfBills": "Fecha de factura",
  "dateSuspencion": "Fecha de suspension",
  "paymentDate": "Fecha de pago",
  "viewBill": "Ver Factura",
  "updateCompany": "Compañía cambiada correctamente",
  "updateQuuestionCompany": "Esta seguro que desea cambiar el estado de esta compañía?",
  "stateCompanyAlert": "Estado de compañía modificado",
  "assignNotification": "Asignar notificaciones",
  "newCompany": "Crear compañía",
  "editCompany": "Editar compañía",
  "manager": "Manager",
  "change": "Cambiar",
  "updateTransaction": "Editar transacción",
  "reconciliations": "Conciliaciones",
  "manualReconciliations": "Consiliaciones manuales",
  "originOfMoney": "Origen del dinero",
  "paymentMethod": "Metodo de pago",
  "paymentReference": "Referencia de pago",
  "valuePaid": "Valor pagado",
  "voucherNumber": "Numero del comprobante",
  "enablePaymentVertebra": "Habilitado para pago vertebra",
  "enableForPayment": "Habilitado para pago",
  "seeProof": "Ver Comprobante",
  "creation": "Creación",
  "billLoad": "Carga de factura",
  "typedBy": "digitada por",
  "approvedBy": "Aprovada por",
  "voucherUpload": "Carga del comprobante",
  "conceptsForConsumption": "Conceptos por consumo",
  "toiletPrice": "Precio del aseo",
  "contributionForTheToilet": "Contribución por el aseo",
  "totalPaidInToilet": "Total pagado en aseo",
  "activeEnergyCurrentMeasurement": "Medida actual de energía activa",
  "previusEnergyCurrentMeasurement": "Medida anterior de eneriga activa",
  "activePowerEnergy": "Factor de energía activa",
  "activeEnergyConsumption": "Consumo de energía activa",
  "activeEnergyRate": "Tarifa de la energía activa",
  "activeEnergyPrice": "Precio de la energía activa",
  "activeEnergyContribution": "Contribución por energía activa",
  "currentMesurementOrReactiveEnergy": "Medida actual de energía reactiva",
  "previusMesurementOrReactiveEnergy": "Medida anterior de energía reactiva",
  "reactivePowerfactor": "Factor de energía reactiva",
  "reactiveEnergyComsuption": "Consumo por energía reactiva",
  "reactiveEnergyrate": "Tarifa por energía reactiva",
  "reactiveEnergiPrice": "Precio de la energía reactiva",
  "contributionForReactiveEnergy": "Contribución por la energía reactiva",
  "hourOfInterruption": "Horas de interrupción",
  "totalPaidEnergy": "Total pagado en energía",
  "currentMeasureOfAqueduct": "Medida actual de acueducto",
  "previusMeasureOfAqueduct": "Medida anterior de acueducto",
  "waterComsuption": "Consumo de agua",
  "basicAqueductRate": "Tarifa basica acueducto",
  "coplementaryAqueductRate": "Tarifa complementaria acueducto",
  "suntaryAqueductRate": "Tarifa suntuario acueducto",
  "basicFixedPriceAqueduct": "Precio fijo básico acueducto",
  "complementaryFixedPriceAqueduct": "Precio fijo complementario acueducto",
  "fixedPriceQueduct": "Precio fijo acueducto",
  "aqueductContribution": "Contribución acueducto",
  "contributionForAqueductFixedPrice": "Contribución por precio fijo acueducto",
  "basicSewerRate": "Tarifa básica alcantarillado",
  "sumpturySewerRate": "Tarifa suntuaria alcantarillado",
  "basicFIxedPriceAqueduct": "Precio fijo básico acueducto",
  "coplementarySewerRate": "Tarifa complementaria alcantarillado",
  "coplementaryFixedPriceAqueduct": "Precio fijo complementario acueducto",
  "fixedPriceSumptaryAqueduct": "Precio fijo suntuario acueducto",
  "seweragePrice": "Precio del alcantarillado",
  "fixedPriceAqueduct": "Precio fijo acueducto",
  "fixedPriceSewerage": "Precio fijo alcantarillado",
  "contributionForSewerage": "Contribución  por alcantarillado",
  "contributionForFixedSeweragePrice": "Contribución por precio fijo alcantarillado",
  "pricePaidForTheAqueductP": "Precio pagado por el acueducto",
  "pricePaidForTheAqueduct": "Precio pagado por acueducto",
  "totalOther": "Total de otros",
  "voluntaryContribution": "Contribución voluntaria",
  "otherToilet": "Otros Aseo",
  "observations": "Observaciones",
  "noComment": "Sin comentarios",
  "deleteComentaryAlertBill": "Usted va a eliminar un comentario de la factura",
  "notComentary": "No hay comentarios",
  "deleteNoveltyBillAlert": "Usted va a eliminar una novedad de la factura",
  "notNovelty": "No hay novedades",
  "tracing": "Seguimiento",
  "by": "por",
  "at": "a las",
  "sorceId": "Origen ID",
  "source": "Origen",
  "destinityID": "Destino ID",
  "destinity": "Destino",
  "date": "Fecha",
  "reference": "Referencia",
  "transferAmount": "Monto transferido",
  "resource": "Recurso",
  "transactionInfo": "Información de transacción",
  "refrenceInfo": "Información de referencia",
  "operationBillAsiigned": "La operacion no tiene una factura asignada.",
  "paymentInfo": "Información del pago",
  "auditInfo": "Información de auditorías",
  "notNoveltyAdd": "No se han agregado novedades",
  "notConceptsAdd": "No se han agregado conceptos",
  "selectionDate": "Debe seleccionar una fecha",
  "transactioExits": "La transacción ha sido cerrada con éxito",
  "startDateOperation": "Fecha de inicio de operación",
  "errorFileUpload": "error en carga de archivo.",
  "imageSizeErr": "la imagen no debe ser mayor a 20MB",
  "transactionEditOk": "Transacción editada correctamente",
  "transactionNewOk": "Transación creada correctamente",
  "nameOperation": "Nombre de la operación",
  "numberBillEnter": "Digite el numero de la factura",
  "numberReferencia": "Numero de referencia",
  "lockedReturn": "Bloquear retroceder",
  "selectionRatesFile": "Click o arrastre el comprobante de la transacción",
  "uploadVaucher": "Cargar comprobantes",
  "uploadFileBIll": "Debe subir una imagen de la factura",
  "editRates": "Editar transacciones",
  "newRates": "Crear transaccion",
  "returnRates": "Retroceder transacción",
  "fileUploadOk": "Archivo subido con exito",
  "uploadReconciliation": "Subir conciliación",
  "reportNewOk": "Reporte creado con exito",
  "yourReporNewOk": "Su reporte fue creado con exito",
  "manualReconciliation": "Reconciliación manual",
  "recolitianOk": "Reconciliacion realizada con exito",
  "totallySelection": "Total seleccionado",
  "reconconciliationManully": "Realizar conciliacion manual",
  "successConsoliditedOperation": "Operación consolidada correctamente",
  "send": "Enviar",
  "addFounds": "Agregar fondos",
  "editFounds": "Editar fondos",
  "updateQuestion": "¿Esta seguro que desea cambiar el estado?",
  "stateResourseOrigen": "Estado del origen de recurso",
  "balance": "Saldo",
  "incomeMonth": "Ingresos mes",
  "expensesMonth": "Egreso mes",
  "seeDetails": "Ver detalle",
  "viewBalanceHistory": "Ver historial de balances",
  "foundAddSuccefully": "Fondo agregado correctamente",
  "balances": "Balance",
  "uploadProof": "Subir comprobantes",
  "proodUploadFile": "Click o arrastre el comprbante de la transacción",
  "detailRates": "Detalle de una transacción",
  "lastClosing": "Último cierre",
  "newResourceSource": "Crear origen de recurso",
  "editResourceSource": "Editar origen de recurso",
  "moveFounds": "Mover fondos",
  "resourceWithoutNumber": "Origen de recurso sin número",
  "toAccept": "Aceptar",
  "deleteResourceSourceAlert": "Esta seguro que desea borrar este origen de recursos?",
  "resourceSourceDeleteOk": "Origen de recursos eliminado correctamente",
  "styateResourceSourceUpdate": "Estado del origen de recurso cambiado correctamente",
  "assignResourceSource": "Asignacion de origen de recursos",
  "pricipally": "Principal",
  "children": "Hijos",
  "income": "Ingresos",
  "expenses": "Egresos",
  "alert": "Alertas",
  "entitie": "Entidad",
  "assignAccess": "Asignar Accesos",
  "sourceResourceOk": "Origen del recurso creado correctamente",
  "resourceHierachy": "Jerarquia del recurso",
  "foundTransfer": "Fondo transferido correctamente",
  "originResource": "Origen del recurso",
  "transactionDetail": "Detalle de transacción",
  "region": "Región",
  "reg": "Reg",
  "janury": "Enero",
  "jan": "Ene",
  "febraury": "Febrero",
  "feb": "Feb",
  "march": "Marzo",
  "mar": "Mar",
  "april": "Abril",
  "apr": "Abr",
  "May": "Mayo",
  "mays": "May",
  "june": "Junio",
  "july": "Julio",
  "jun": "Jun",
  "jul": "Jul",
  "august": "Agosto",
  "aug": "Ago",
  "september": "Septiembre",
  "sep": "Sep",
  "october": "Octubre",
  "oct": "Oct",
  "november": "Noviembre",
  "nov": "Nov",
  "december": "Diciembre",
  "dec": "Dic",
  "general": "General",
  "gas": "Gas",
  "admin": "Admin",
  "tFija": "T. Fija",
  "tMovil": "T. Movil",
  "tv": "Televisión",
  "stateAccount": "Estado de cuenta",
  "supplier": "Proveedor",
  "restoreInvoice": "Restaurar factura",
  "seeInvoiceAndReceipt": "Ver factura y comprobantes",
  "uploadfBill": "Cargar factura",
  "unpaidInvoiceForVertebra": "Factura no pagada por vertebra",
  "NotDataViews": "No existen datos para mostrar",
  "accountPointBlock": "Cuenta o punto bloqueados",
  "retoreInvoicePeding": "Restaurar factura a pendiente",
  "restore": "Restaurar",
  "support": "Soporte",
  "forPayment": "Por pagar",
  "tracklingUpdateOk": "Seguimiento actualizado correctamente",
  "noveltyPayment": "Novedades de pagos",
  "follows": "Seguimientos",
  "yourRemoveBillFollow": "Usted va a eliminar un seguimiento de la factura",
  "revised": "Revisada",
  "revisedAccount": "Cuenta revisada",
  "billUpdatedAlertPunding": "Esta seguro que desea restaurar esta factura a PENDIENTE?",
  "areYouSure": "Estas seguro?",
  "billUpdateAlertFingered": "Usted va a cambiar el estado de su factura a DIGITADA",
  "paymentNotAuthorized": "Pago no autorizado",
  "month": "Mes",
  "editDateAssing": "Editar fecha asignada",
  "statePaynment": "Estado de pago",
  "plataformNotRegister": "No registrado en plataforma",
  "stateFingered": "Estado en digitacion",
  "companyes": "Compañías",
  "statics": "Estadisticas",
  "rejectBillAlert": "Esta seguro que desea restaurar el factura rechazada?",
  "billRestoredOk": "Factura restaurada correctamente",
  "budgetaryAddAccount": "Esta seguro que desea agregar esta cuenta presupuestaria?",
  "accountAddOk": "Cuenta agregada correctamente al presupuesto",
  "isSecureBill": "Esta seguro que desea revisar esta factura?",
  "accountRevised": "Cuenta revisadas",
  "isSecurePaymenChildren": "Esta seguro que desea propagar el pago en las facturas hijas?",
  "paymentOkAlert": "Pago propagado correctamente",
  "viewVoucher": "Ver Comprobante",
  "reAsign": "Reasignar",
  "addNoveltyAccount": "Agregar novedad de cuenta",
  "restoredRechazed": "Restaurar Rechazada",
  "addBudget": "Agregar a presupuesto",
  "changeBudget": "Cambiar el presupuesto",
  "Outof_budget": "Fuera de presupuesto",
  "autorizedPayment": "Autorizar pago",
  "addNewInvoce": "Agregar Novedad de factura",
  "addNoveltyToTracking:": "Agregar Novedad al seguimiento",
  "slopes": "Pendientes",
  "totallyPayment": "Total a pagar",
  "paid": "Pagadas",
  "totalPaid": "Total pagado",
  "assigns": "Asignado",
  "infoBill": "Informacion de factura",
  "billPendient": "Facturas Pendientes",
  "amountPendient": "Monto Pendientes",
  "porcetain": "Porcentaje",
  "rulesAmount": "Reglas Monto",
  "providers": "Prestadores",
  "new": "Nuevo",
  "assignMissing": "Asignar Faltantes",
  "redistribute": "Redistribuir",
  "uploadChart": "Cargando la gráfica",
  "updateDate": "Se han actualizado los datos.",
  "and": "y",
  "ejectBoots": "Ejecución de bots",
  "paymentMarketerService": "P. Servicio mas pagados",
  "digitBillAlerts": "Digitacion, Facturas Digitadas Alertas",
  "energyConsumerd": "Energía consumida",
  "lastUpdated": "Última vez actualizado:",
  "value": "Valor",
  "status": "Estado",
  "vertebots": "Vertebots",
  "downloadBill": "Descargas de facturas",
  "infovertebots": "Información de vertebots",
  "ejecstBoots": "Ejecutar vertebots",
  "idFather": "Id Padre",
  "idOFCompany": "Id de Compañia",
  "totalFather": "Total Padre",
  "noStartDate": "Sin fecha de inicio",
  "noEndDate": "Sin fecha de finalización",
  "completed": "Completadas",
  "mistake": "Errores",
  "viewDetailOperation": "Ver Detalle operación",
  "displayInformationAbout": "Desplegar información de",
  "hideInformationFrom": "Ocultar información de",
  "displayLogOf": "Desplegar log de",
  "hideLogFrom": "Ocultar log de",
  "uploadedSuccessfully": "subido correctamente",
  "whenTryingAlert": "al tratar de subir el archivo intente de nuevamente error",
  "vertebotStartOk": "Vertebot iniciado con exito",
  "ocrEjectOk": "OCR ejeutado con exito",
  "oneMeter": "Un medidor",
  "allMeter": "Todos los medidores",
  "youMustUploaderFile": "Debe subir un archivo",
  "clickUploadFile": "Click o arrastre el archivo",
  "selectionDateMeter": " Seleccionar una fecha",
  "selectionDateDownloadBullAndAccount": "Seleccionar una fecha para descargar todas las facturas de una cuenta",
  "treeDownload": "Arbol de descarga",
  "attempts": "Intentos",
  "noBillingDate": "Sin fecha de facturación",
  "lastDateAttempts": "Fecha Ultima intento",
  "noDateAttemptsColumns": "No tiene fecha de ultimo intento",
  "datUpdate": "Fecha de actualización",
  "notDateUpdate": "Sin Fecha de actualización",
  "dateNew": "Fecha de creación",
  "notDateNew": "Sin Fecha de creación",
  "selectionOne": "Seleccione uno",
  "selectionOptionViews": "Seleccione una opción para visualizar los tableros de consumos",
  "rememberOption": "Recuerde que esta opción puede tardar un poco",
  "newLoad": "Crear carga",
  "configurateAvanzedLoad": "Configuración avanzada de carga para medidor tipo",
  "updatesMeters": "EditarMedidor",
  "stateMeterUpdate": "Estado de medidor modificado",
  "actionStateCanceled": "Acción de cambio de estado cancelada",
  "infoLoad": "Información de carga",
  "createAlertMeter": "Crear alertas de medidor",
  "description": "Descripción",
  "meteer": "Medidor",
  "typeMeter": "Tipo de medidor",
  "viewUrl": "Ver URL",
  "configAvanzed": "Conf. Avanzada",
  "viewDetail": "Ver detalle",
  "loadUpdateOk": "Carga modificada correctamente",
  "newLoadOk": "Carga creada correctamente",
  "meterss": "Medidores",
  "inserDescriptionLoad": "Ingrese una descripción de la carga...",
  "uploadSupport": "Subir soporte",
  "textRepeatVoucherUpload": "Ya existe un comprobante cargado, en caso de subir uno nuevo se eliminará el comprobante existente.",
  "instalation": "Instalador",
  "multiplicationFactor": "Factor multiplicación",
  "relationCTS": "Relación CTS",
  "typeOfMeasure": "Tipo de medida",
  "circuitType": "Tipo de circuito",
  "powerFactor": "Factor potencia",
  "voltage": "Voltaje",
  "createAlert": "Crear alerta",
  "editAlert": "Editar alerta",
  "alertDeleteAlert": "Esta seguro que desea borrar esta alerta?",
  "alertDeteleOk": "Alerta eliminado correctamente",
  "var": "Variable",
  "temp": "Tiempo",
  "alertUpodateOk": "Alerta modificada correctamente",
  "alertNewOk": "Alerta creada correctamente",
  "pleaseInsertVar": "Por favor ingresa una variable!",
  "pleaseInsertValue": "Por favor ingresa un valor",
  "pleaseInsertTemp": "Por favor ingresa un tiempo valido",
  "nameOfCompany": "Nombre de compañia",
  "idGroup": "ID De grupo",
  "nameOfGroup": "Nombre de grupo",
  "idPoint": "ID De punto",
  "nameOfPoint": "Nombre del punto",
  "idMeter": "ID De medidor",
  "nameMeter": "Nombre del medidor",
  "viewResource": "Ver Recurso",
  "idOfLoad": "ID De la carga",
  "nameOfLoad": "Nombre de la carga",
  "infoAvanzedLoad": "Información avanzada de la carga",
  "operationInfo": "La operacion no tiene una información",
  "newMeter": "Crear medidor",
  "avanzedConfig": "Configuración avanzada",
  "ediMeter": "Editar medidor",
  "infoMeter": "Información de medidor",
  "updatePasswordMeter": "Cambiar contraseña de medidores",
  "serial": "Serial",
  "protocol": "Protocolo",
  "dateInstalation": "Fecha de Instalación",
  "lastReport": "Último Reporte",
  "serialOfmMeter": "Serial del medidor",
  "deviceName": "Nombre del dispositivo",
  "errorAddinfo": "Error al agregar la información",
  "codNameLastAdd": "El codigo o el nombre de la base de datos ya fueron agregados",
  "NameBDLastAdd": "El nombre de la base de datos ya fue agregado",
  "addProtocolMod": "Adicionar protocolos modbus",
  "addHeader": "Adicionar cabeceras",
  "dataAcquisition": "Adquisición de Datos",
  "frecuencyUnit": "Unidad de frecuencia",
  "frecuencyValue": "Valor de frecuencia",
  "configHeader": "Configuración cabecera",
  "addConfigHeader": "Agregar configuración de cabecera",
  "nameHeader": "Nombre de cabecera",
  "nameBD": "Nombre BD",
  "action": "Acción",
  "directionIp": "Dirección IP",
  "port": "Puerto",
  "protocolModbus": "Protocolo modbus",
  "addProtocol": "Agregar Protocol",
  "register": "Registro",
  "dataLength": "Longitud de dato",
  "tData": "T. Dato",
  "typeData": "Tipo de dato",
  "unit": "Unidad",
  "concentrator": "Concentrador",
  "sim": "Sim",
  "simNumber": "Numero de sim",
  "subType": "Sub tipo",
  "nameOfHeader": "Nombre de cabecera",
  "idConcentrator": "ID De concentrador",
  "adquitionData": "Adquisición de la data",
  "infoOfMeter": "Información del medidor",
  "infoAvanzedMeter": "Información avanzada del medidor",
  "configModBus": "Configuración ModBUS",
  "notInfoOperation": "La operacion no tiene una información.",
  "operation": "Operación",
  "typeEmail": "Tipo de Correo",
  "detailAudit": "Detalle de Auditoría",
  "fileNoXlsx": "El archivo no es xlsx",
  "fileNoZip": "El archivo no es zip",
  "uploader": "Cargador",
  "selectEntitie": "Debe seleccionar una entidad",
  "selectAction": "Debe seleccionar una acción",
  "downloadExample": "Descargar Ejemplo",
  "selectionFile": "Debe seleccionar una archivo",
  "selectFile": "Seleccionar archivo",
  "selectOneField": "Debe seleccionar al menos un campo",
  "selectMergeUnique": "Seleccione combinación única...",
  "stringZero": "Tomar vacíos como String vacío",
  "selectionMethods": "Seleccione método",
  "firstProcess": "Iniciar Proceso",
  "History": "Historial",
  "entiti": "Entidad",
  "process": "Procesar",
  "mergeUnique": "Combinación Única",
  "treatmentUpdating": "Tratamiento al actualizar",
  "aprovved": "Aprobado",
  "isSecureQuestion": "¿Esta seguro?",
  "generateReport": "Generar reporte",
  "dateOfReport": "Fecha del reporte",
  "selectionYear": "Debe seleccionar el año",
  "criteryDownload": "Criterio de descarga",
  "selectionCritery": "Debe seleccionar un criterio",
  "criteryEDownload": "Criterio a descargar",
  "allGroups": "Todos los grupos",
  "allCompanies": "Todas las compañias",
  "selectionAllGroup": "Debe seleccionar al menos un grupo",
  "AllGroupsWereSelected": "Se eligieron todos los grupos",
  "AllCompaniesWereSelected": "Se eligieron todas las compañias",
  "reportCreateExit": "Reporte creado con exito",
  "selectionMonth": "Debe seleccionar el mes",
  "selectionDates": "Seleccione una fecha",
  "allPoint": "Todos los puntos",
  "selectionOnePoint": "Debe seleccionar al menos un punto",
  "selectioAllPoint": "Se eligieron todos los puntos",
  "selectionMeter": "Debe seleccionar al menos un medidor",
  "AllMeterSelection": "Se eligieron todos los medidores",
  "inserDateValidate": "Debe ingresar una fecha valida",
  "typesServices": "Tipos de servicio",
  "selectionOneService": "Debe seleccionar al menos un servicio",
  "selectionAllService": "Se eligieron todos los servicios",
  "selectionOneDigitation": "Debe seleccionar al menos un digitador",
  "selectionAllType": "Se eligieron todos los tipos",
  "creates": "Creado",
  "periode": "Periodo",
  "payment": "Pagado",
  "dateUpload": "Fecha de carga",
  "dateAssing": "Fecha asignada",
  "dateExpedition": "Fecha de expedición",
  "selectionOneRegional": "Debe seleccionar al menos una regional",
  "options": "Opciones",
  "consumedMonth": "Consumo mensual",
  "separateCleanLight": "Separa aseo",
  "viewsSubProject": "Mostrar Subproyecto",
  "toChooseAllMarketer": "Se eligieron todas las comercializadoras",
  "firstDateReport": "Primera fecha del reporte",
  "secondDateReport": "Segunda fecha del reporte",
  "yearAndMonth": "Año y mes",
  "allServices": "Todos los servicios",
  "ConvertValuePositive": "Convertir a Valores Positivos",
  "logSistem": "Log Sistema",
  "newVersion": "Nueva versión",
  "allAccount": "Todas las cuentas",
  "selectionOneAccount": "Debe seleccionar al menos una cuenta",
  "allsAccount": "Se eligieron todas las cuentas",
  "reportCreaoteOkAlert": "Reporte creada correctamente",
  "selectionOneGroup": "Debe seleccionar un grupo",
  "monthReport": "Mes del reporte",
  "load": "Carga",
  "preview": "Previsualizar",
  "engrave": "Grabar",
  "budgetAvailible": "No hay presupuestos disponibles",
  "check": "Revisar",
  "payBill": "Pagar factura",
  "reviewBill": "Revisar factura",
  "changePayment": "Cambiar Pago",
  "spradPaymentAccount": "Propagar pago de cuenta",
  "viewInfo": "Ver Información",
  "createNoveltys": "Crear Novedades",
  "staticsBill": "Estadisticas de Facturas",
  "selectionBudgetAddBill": "Seleccione un presupuesto para agregar a la factura",
  "config": "Configuración",
  "accountRestore": "Cuenta restaurada",
  "accountGirl": "Cuenta hija",
  "outOfBudget": "Fuera del presupuesto",
  "NotPlataformRegister": "No registrado en plataforma",
  "expiration": "Fecha de vencimiento",
  "suspend": "Suspensión",
  "due": "Due",
  "notBNudget": "Sin presupuesto",
  "dataPayment": "Datos de pago",
  "delet": "Borrar",
  "TheFileDelete": "El archivo ha sido eliminado",
  "recordUploadFile": "Recuerde que se subirá una nueva factura, indicando que llegó más de una factura para este mes",
  "continue": "Continuar",
  "newUploabill": "Subir una nueva factura",
  "newUpdateBill": "Cambiar Factura",
  "alertRejectBill": "Recuerde que se las facturas rechazas seran eliminadas por completo",
  "rejectBill": "Rechazar factura",
  "deleteBill": "Eliminar factura",
  "deleteVoucher": "Eliminar comprobante",
  "dateBillPrompt": "Fecha de pago oportuno",
  "NotObservations": "No hay observaciones",
  "infoBillOf": "información de la factura de",
  "obsOfTheBill": "Observaciones de la factura",
  "noveltyOfTheBill": "Novedades de la factura",
  "followBill": "Seguimientos de la factura",
  "conceptTheBill": "Conceptos de la factura",
  "moneyBags": "Bolsas de dinero",
  "phones": "Telefonía",
  "mobilePhone": "Telefonía Móvil",
  "day": "Día",
  "selection": "Seleccionar",
  "obs": "Observación",
  "selections": "Seleccionados",
  "recharge": "Recargar",
  "aPublic": "A. Publico",
  "accountInactive": "Cuenta inactiva",
  "pointInactive": "Punto inactivo",
  "accountAndPointInactive": "Cuenta y punto inactivos",
  "viewAdmiPoint": "Ver Admi. Puntos",
  "notInvoiced": "No facturada",
  "configUpdateNotification": "Configuración de notificación actualizada",
  "configTextNotification": "Configuración de notificaciones de",
  "notificarEi": "Notificar a",
  "whenUploadBill": "cuando se cargue una factura",
  "whenUploadVoucher": "cuando se cargue un comprobante",
  "whenRejectBill": "cuando se rechace una factura",
  "whenSendPetitionBillMarketer": "peticion de factuas por correo",
  "notificationTypeUser": "Notificar al tipo de usuario",
  "managers": "Gestor",
  "payer": "Pagador",
  "client": "Cliente",
  "loa": "Carga",
  "notTypeUser": "Sin tipo de usuario",
  "petitionBillAcoount": "Petición de factura a cuenta",
  "companyUpdateOk": "Compañía actualizada correctamente",
  "companyNewOk": "Compañía creada correctamente",
  "pleaseInserrNameValid": "Por favor ingresa un nombre valido!",
  "pleaseInsertTypeValid": "Por favor ingresa un tipo valido!",
  "updateStateAccount": "Cambiar estado de cuenta",
  "auditOfPoint": "Auditorias de puntos",
  "updateStatePointAsk": "Esta seguro que desea cambiar el estado de este punto?",
  "stateOfPointUpdate": "Estado del punto modificado",
  "verificedStanBY": "Verificación pendiente",
  "correo": "Correo",
  "stateOfPoint": "Estado de punto",
  "exportListUpdate": "Exportar listados de cambios",
  "permitNotificationPayment": "Permite notifiaciones de pago",
  "authorizedPaymet": "Autorizado pago",
  "lvlSocio": "Nivel socioeconomico",
  "lvlVoltage": "Nivel de voltage",
  "userRealizedUpdate": "Usuario que realiza el cambio",
  "notDateOfOperation": "Sin fecha de opración",
  "noveltyAccount": "Novedades de cuenta",
  "insertFilePlaneEnel": "Subir archivo padre de Enel",
  "updateStateofAccount": "Cambiar estado de cuenta",
  "IsSecureUpodateStateAccountAsk": "Esta seguro que desea cambiar el estado de esta cuenta?",
  "stateAcconuntModif": "Estado de la cuenta modificado",
  "auditAccountNumber": "Auditorias de cuenta numero",
  "ofPoint": "del punto",
  "pServicio": "P. Servicio",
  "dateTI": "Fechas T - I",
  "accountFathers": "C. padre",
  "idNeuAccount": "ID nueva cuenta",
  "newNameOfAccount": "Nuevo nombre de cuenta",
  "newMarketerService": "Nuevo prestador de servicio",
  "updateRealizedFor": "cambio realizado por",
  "lastMarketer": "anterior comercializadora",
  "updateMarketerForUser": "Se cambio de comercializadora por el usuario",
  "he": "el",
  "eiTheMarketer": "a la comercializadora",
  "withAccountNumber": "con número de cuenta",
  "notUpdateMarketer": "No se cambio de comercializadora",
  "dateTimelyPayment": "Fecha oportuna de pago",
  "dateInitialOfBilling": "Fecha inicial de facturación",
  "paymentAuthorized": "Autorizada pago",
  "authorizedForPaiment": "Autorizado para pago",
  "dateServices": "Servicios fechas",
  "NumberCuentaEnergy": "# cuenta de energía",
  "numberAccountOfStreetLight": "# cuenta de A. Publico",
  "numberAccountWater": "# cuenta de agua",
  "numberAccountOfSewerage": "# cuenta de alcantarillado",
  "numberAccountCleanlines": "# cuenta de aseo",
  "numberAccountGas": "# cuenta de gas",
  "voltageLvl": "Nivel de tensión",
  "infoOdfTheAccount": "Información de la cuenta",
  "editNovelty": "Editar novedades",
  "theGroupDeleteOk": "El grupo ha sido eliminado con éxito",
  "codOfgroup": "Codigo del grupo",
  "notName": "Sin nombre",
  "dateUploadOfBill": "Fecha de carga de comprobante",
  "dateOfPaymentBill": "Fecha de pago",
  "back": "Retroceder",
  "companys": "Compañías",
  "nameOftheTransaction": "Nombre de la transacción",
  "balanceFirst": "Balance inicial",
  "balanceEnd": "Balance final",
  "clickFileRates": "Click o arrastre el archivo de la transacción",
  "manualReconciliationss": "Conciliación manual",
  "reconciliation": "Conciliadas",
  "notReconciliation": "No Conciliada",
  "notDate": "Sin fecha",
  "notReconciliatioons": "No reconciliada",
  "operationConsolidedOk": "Operación consolidada correctamente",
  "historyBalances": "Historial de balances",
  "uploadProofs": "Subir comprobante",
  "lastClose": "Ultimo cierre",
  "dateNotOfFirst": "Fecha inicio",
  "createResouceSource": "Crear origen de recurso",
  "sourceResourceNotNumber": "Origen de recurso sin numero",
  "states": "Estados",
  "sorceResourceOk": "Origen del recurso correctamente",
  "budgetCalculation": "Calculo de presupuesto",
  "gestionBudget": "Gestionar presupuestos",
  "isSecurteDeleteBudgetAlertAsk": "Esta seguro que desea borrar este presupuesto?",
  "budgetDeleteOk": "Presupuesto eliminado correctament",
  "isSecureAskAprovedBudget": "¿Esta seguro que desea aprobar este presupuesto?",
  "theBudgetisAproved": "El presupuesto ha sido aprobado",
  "isSecureDuplicateBudgetAsk": "Esta seguro que desea duplicar este presupuesto",
  "budgetDuplicateOk": "Presupuesto duplicado correctamente",
  "selectAQuoteToSync": "Seleccione un presupuesto para sincronizar",
  "budgetCreateOk": "Presupuesto creado correctamente",
  "note": "Nota",
  "textBudgetOne": " Las cuentas que se utilizaran para el cálculo de presupuesto, son las están autorizadas para pago. Si las facturas no han llegado y no se han digitado se utilizara la fecha aproximada de pago el monto aproximado de pago.",
  "generateBuget": "Generar Presupuesto",
  "dateCreate": "Fecha creación",
  "view": "Visualizar",
  "aprovedQuote": "Aprobar presupuesto",
  "downloadDetail": "Descargar Detalle",
  "duplicateQuote": "Duplicar presupuesto",
  "syncBudget": "Sincronizar presupuesto",
  "budgetToSyncOk": "Presupuesto sincronizado con exito",
  "accounrAddOk": "Cuenta agregada correctamente",
  "isPaymentregisterBudget": "Este pago ya se encuentra registrado en el presupuesto",
  "budgetUpdateOk": "Presuspuesto modificado correctamente",
  "isSecureUpdateQuotes": "Esta seguro que desea cambiar el estado del presupuesto",
  "budgetSaveOk": "Presuspuesto guardado correctamente",
  "addAccountBudget": "Agregar cuenta al presupuesto",
  "filterBills": "Filtrar Facturas",
  "lastBills": "Ult. Factura",
  "codeOffice": "Cod. Oficina",
  "createRole": "Crear rol",
  "editRole": "Editar rol",
  "notsverificied": "No Verificado",
  "notSendNotificationsAlert": "Se ha desactivado el envio de notificaciones",
  "sendNotificationsAlert": "Se ha Activado el envio de notificaciones",
  "petitionBillDesactivate": "Petición de Factura Desactivada",
  "petitionBillActivate": "Petición de Factura Activada",
  "codMovii": "Codigo movii",
  "digitVerification": "Digito de verificación",
  "insertNumberDigitation": "Debe digitar el numero de verificación",
  "modo": "Modo",
  "notificationDelete": "Notificación eliminada correctamente",
  "deleteNotificationAlerQuestion": "Esta seguro que desea borrar esta notificación?",
  "createNotification": "Crear notificación",
  "editNotification": "Editar notificación",
  "updateNotificationAlert": "Notificación modificada correctamente",
  "notificationCreateOk": "Notificación creada correctamente",
  "petitionBill": "Peticion de factura a",
  "createConcepts": "Crear concepto",
  "subService": "Sub Servicio",
  "textOneCreateConcetp": "Deshabilitar subproyectos en caso de que no aplique para el tipo deservicio",
  "equevalenceExist": "Esta equivalencia ya existe",
  "equivalence": "Equivalencia",
  "atMenosEquivalence": "Al menos una equivalencia",
  "fieldObligatoeyValue": "Este campo es obligatorio, por favor ingrese un valor",
  "nameOfEquevalence": "Nombre de la equivalencia",
  "addNewqEquivalence": "Agregar una nueva equivalencia",
  "saveEquievalence": "Guardar equivalencia",
  "saveUpdate": "Guardar cambios",
  "confir": "Confirmar",
  "configs": "Configuraciones",
  "audit": "Auditoría",
  "odoo": "Odoo",
  "emailss": "Correos",
  "psERVICE": "P. de Servicio",
  "notCity": "Sin ciudad",
  "notdeparment": "Sin departamento",
  "notPropieruty": "Sin propiedad",
  "notLvlVariedade": "Sin nivel de tensión",
  "rateOptional": "Tarifa opcional",
  "basic": "basico",
  "valueFije": "Valor Fijo",
  "saldeMinime": "Salarios Minimos",
  "ratesUpdateOk": "Tarifa modificada correctamente",
  "ratesOkCreate": "Tarifa creada correctamente",
  "generates": "Generación",
  "tranmition": "Transmisión",
  "distribution": "Distribución",
  "comercialization": "Comercialización",
  "retriction": "Restricciones",
  "lossesAndReduction": "Pérdidas y reducciones",
  "valueEnergy": "Valor de energía",
  "optionTarida": "Opción tarifaria",
  "valueOption": "Valor opcional",
  "textRatesOne": "Ya existe un archivo cargado, en caso de subir uno nuevo se eliminara el anterior",
  "clickFileRatess": "Click o arrastre el archivo de la tarifa",
  "createSetting": "Crear configuración",
  "editSetting": "Editar configuración",
  "isSecureDeleteSetting": "Esta seguro que desea borrar esta configuración?",
  "settingDeleteOk": "Configuración eliminado correctamente",
  "valueColumns": "Valores",
  "ValueOf": "Valores de ",
  "viewValues": "Ver valores",
  "table": "Tablas",
  "tableSyncro": "Tabla syncronizada",
  "syncro": "Sincronizar",
  "viewss": "Ver",
  "detailOdoo": "Detalle Odoo",
  "displayInformation": "Desplegar información",
  "ocultInfo": "Ocultar información",
  "calculatorAmbiental": "Calculadora ambiental",
  "editPoint": "Editar punto",
  "isSecureDeleteElement": "Esta seguro que desea eliminar este elemento?",
  "elementDeleteOk": "Elemento eliminado correctamente",
  "iluminationActivate": "Inversion de iluminacion activada",
  "notIluminationDeactivate": "No tiene inversion de iluminación",
  "pointVisit": "Punto Visitado",
  "notPointVisit": "Se ha desactivado la visita del punto",
  "aireVisit": "Inversion de aires acondicionado activada",
  "notAireVisit": "No tiene inversion de aires acondicionado",
  "exportMultiPoint": "Exportar MultiPuntos",
  "iluminations": "Inversion de iluminación",
  "aire": "Inversion en Aire Acondicionado",
  "visit": "Visitado",
  "deleteOeditOne": "Solo puede eliminar o editar uno a la vez",
  "downloadReport": "Descargar Reporte",
  "typeOffice": "Tipo de oficina",
  "numbersEmployer": "No. de Empleado",
  "activa": "Activa",
  "reactiva": "Reactiva",
  "light": "Alumbrado",
  "acueduct": "Acueducto",
  "numberAccounts": "No. de Cuenta",
  "numberMeters": "Numero Medidor",
  "shedules": "Horario",
  "element": "Elemento",
  "photo": "Fotos",
  "elementAddOk": "Elemento agregado correctamente",
  "assingGestor": "Asignar usuarios gestor",
  "userAddOk": "Usuarios agregado correctamente",
  "userDeleteOk": "Usuarios eliminado correctamente",
  "sinAudit": "Sin auditoria",
  "auditPayment": "Auditoria pago",
  "useraddgestor": "Usuarios añadidos al punto correctamente",
  "userDeletedGestor": "Usuarios añadidos al punto correctamente",
  "deleteUser": "Eliminar usuario",
  "assignUsers": "Asignar usuario",
  "adimin": "Administración",
  "totallyBillAnual": "Total Factura Anual",
  "totallyBillMonth": "Total de Factura Mensual",
  "billSinBill": "Factura Sin Subir",
  "billMonth": "Factura frecuencia mensual",
  "billDigitid": "Facturas digitadas",
  "billFrequencyMontly": "Factura frecuencia bimensual",
  "billPedientDigit": "Facturas pendientes por digitar",
  "billPendie": "Facturas pendiente",
  "billFrequencyTri": "Factura frecuencia trimestral",
  "billPayment": "Facturas pagada",
  "billFrequencyAnual": "Factura frecuencia anual",
  "billReject": "Facturas rechazadas",
  "formule": "Formula",
  "changefrecuency": "Cambiar frecuencia del mes",
  "updloadbillmethod": "Metodo de carga de factura",
  "makechange": "Cambio realizado con exito",
  "incumbent_marketer": "Prestador incumbente",
  "emailverification": "Verificar correo",
  "removeemailnotification": "Quitar verificación a correo",
  "executed_mode": "Presupuesto ejecutado",
  "marketer_user_type": "Tipo de usuario",
  "applyfilters": "Filtros aplicados",
  "supervisor": "Supervisar vizualizacion de pagos",
  "companies": "Compañias",
  "pay_regionals": "Grupos",
  "types_pay": "Tipos",
  "pays_marketers": "Prestadores",
  "asignee": "Pagador",
  "payorder": "Orden",
  "filter": "Filtrar",
  "supervisortag": "Supervisor",
  "issue": "Fecha de emisión",
  "reviewModal": "Revisión de factura",
  "review": "Revisar",
  "reviewsubtype": "Sub tipo de revisión",
  "approvepayment": "Aprobado para pago",
  "removereview": "Remover revisión",
  "restorereview": "Editar revisión",
  "payappropve": "Aprobada para pago",
  "approved_payment": "Aprobado para pago",
  "digitation_status_filter": "Estado de digitacion",
  "datebill": "Fecha de inicio de facturación",
  "estimate_issue_date": "Estimación de fecha de emisión",
  "budget_apply": "Aplica para presupuesto",
  "active": "Activo",
  "uploadBill": "Cargar factura",
  "uploaded_status": "Estado de Carga de factura",
  "client_upload_bill": "El cliente sube la factura",
  "charger": "Cargador",
  "update_bill": "Actualizar factura",
  "consumption_matrix": "Matriz",
  "revision_status": "Estado de revisión",
  "services_config": "Configuración de servicios",
  "services_message": "Debe seleccionar un servicio",
  "update_settings": "Actualización de la configuración",
  "odoo_alert": "Generar alertas odoo",
  "fat_file": "Archivo plano",
  "file": "Archivo",
  "conciliations": "Conciliaciones",
  "movementsandtransfers": "Movimientos y transferencias",
  "created_at": "Fecha de creacion",
  "voucher_number": "Numero de comprobante",
  "paid_date": "Fecha carga pago",
  "user_created": "Usuario",
  "target": "Destino",
  "payment_date": "Fecha de carga de comprobante",
  "date_bill": "Fecha de factura",
  "real_payment": "Valor pagado",
  "regional_name": "Regional",
  "number_bill": "Número de factura",
  "payment_reference": "Referencia de pago",
  "cash_source_name": "Fuente de recursos",
  "account_name": "Número de cuenta",
  "payment_method_marketer_name": "Método de pago",
  "is_consolidated_true": "Conciliada",
  "is_consolidated_false": "No conciliada",
  "payment_tax": "Impuestos",
  "date_paid": "Fecha de pago",
  "rollback": "Rollback",
  "user_uploaded_voucher": "Usuario que realiza el pago",
  "consolidated": "Conciliada",
  "bill_reviewed": "Auditoria",
  "bill_load": "Bitacora",
  "not_consolidated": "No Conciliada",
  "property_level": "Nivel de propiedad",
  "voltaje_level": "Nivel de tensión",
  "phone_number": "Teléfono",
  "guru_groups": "Grupos de Guruve",
  "clear_selections": "Limpiar seleccionados",
  "places": "Puntos",
  "max_three_selected": "Puede seleccionar máximo tres grupos",
  "history": "Historial",
  "get_history_id_message": "Debes seleccionar un usuario para mostrar sus datos de historial.",
  "operation_name": "Operación",
  "cash_source": "Movimiento",
  "see_history": "Ver Historial del Usuario",
  "messages": "Mensajes",
  "see_messages": "Ver mensajes del usuario",
  "schedules": "Mensajes Programados",
  "message_title": "Título del mensaje",
  "message": "Mensaje",
  "buttons": "Botones",
  "guruve_buttons_add": "Agregar botones al mensaje",
  "section_title": "Título de la sección",
  "body_message": "Ingrese las especificaciones del formulario.",
  "body_message_title": "Cuerpo del formulario",
  "expected_response_type": "Tipo de respuesta",
  "list_text_message": "Nombre de la lista",
  "guru_message_types": "Tipo de mensaje",
  "guru_messages_status": "Estado del mensaje",
  "total_sent": "Total de mensajes enviados",
  "send_date": "Fecha de envío",
  "send_date_and_hour": "Fecha y hora de envío",
  "included_groups": "Grupos incluidos",
  "excluded_groups": "Grupos excluidos",
  "included_users": "Usuarios incluidos",
  "excluded_users": "Usuarios excluidos",
  "includ_all": "Incluye todo",
  "programing": "Programación",
  "targets": "Destinos",
  "get_schedule_message_info": "Debe seleccionar un mensaje para visualizar su configuración.",
  "aprove": "Aprobar",
  "reject": "Rechazar",
  "duplicated": "Duplicar",
  "send_test": "Enviar prueba",
  "send_date_and_hour_message": "Su mensaje fue programado para ser enviado a la y fecha hora deseada, si desea modificarlo puede hacerlo antes del tiempo establecido.",
  "change_message": "Cambiar mensaje",
  "click_and_drag": "Haz clic o arrastra el archivo a esta área para subirlo",
  "click_and_drag_message": "Soporte para carga individual. Se prohíbe estrictamente la carga de datos de la empresa u otros archivos no autorizados.",
  "changePriority": "Cambiar Prioridad",
  "changeNormalToPriority": "Cambiar a Prioridad Normal",
  "changeHighToPriority": "Cambiar a Prioridad Alta",
  "priority": "Prioritario",
  "expired": "Vencido",
  "request_approval": "Solicitar aprobación",
  "building": "EN CONSTRUCCION",
  "ready_for_approval": "PENDIENTE APROBACION",
  "scheduled": "APROBADO Y PROGRAMADO",
  "sent": "ENVIADO",
  "rejected": "RECHAZADO",
  "cancelled": "CANCELADO",
  "type_upload_method": "Tipo de método de carga",
  "review_sub_type": "Sub tipo de revisión",
  "assigned": "Asignado",
  "sending_message": "Enviando mensaje",
  "delivered": "Entregado",
  "received": "Recibido",
  "seen": "Visto",
  "text": "Texto",
  "list": "Lista",
  "media": "Medios",
  "button": "Botón",
  "button_reply": "Respuesta de botón",
  "list_reply": "Respuesta de lista",
  "sticker": "Sticker",
  "incoming": "Entrante",
  "no_message": "No hay mensajes",
  "sent_at": "Enviado",
  "delivered_at": "Entregado",
  "read_at": "Leído",
  "see_more": "Ver más",
  "information": "Información",
  "add_form_field": "Agregar campo al formulario",
  "add_list_item": "Agregar item a la lista",
  "add_button_field": "Agregar botón al formulario",
  "edit_schecule_message": "Editar mensaje programado",
  "saving": "Guardando...",
  "payment_method": "Método de pago",
  "total_pending": "Total pendientes",
  "total_pending_priority": "Total pendientes prioritarios",
  "edit_message_name": "Editar nombre del mensaje",
  "edit_schedule": "Editar contenido",
  "clear_last_selections": "Limpiar ultima selección",
  "total_user_bill_downloads": "Total de bitácoras realizadas",
  "estimated_upload_date": "Fecha estimada de carga",
  "upload_month": "Mes de carga",
  "scheduled_at": "Fecha de programación",
  "mode": "Modo",
  "assignee_charger": "Cargador asignado",
  "assignee_payer": "Pagador asignado",
  "digitation_status": "Estado de digitación",
  "user_bill_download": "Usuario que descarga la factura",
  "payment_aut": "Autorizado para pago",
  "remove_children": "Eliminar hijos",
  "remove_children_account": "Eliminar del padre",
  "action_sucesfull": "Acción realizada con éxito",
  "remove_account_of_bill_parent": "Eliminar factura de la cuenta padre",
  "remove_pay_from_bill": "¿Desea remover del pago esta factura?",
  "payment_method_suggest": "Método de pago sugerido",
  "direct_debit_payment": "Pago domiciliado",
  "upload_parent_file_bill": "Subir factura padre",
  "clickUploadBill": "Click para subir factura",
  "simple_mode": "Modo simple",
  "report_mode": "Modo de reporte",
  "childs": "Hijas",
  "parent": "Padre",
  "loader": "Cargador",
  "add_selected": "Agregar seleccionados",
  "automatic_debit": "Débito automático",
  "energy_border": "Frontera",
  "account_cession": "Cesión de cuenta",
  "account_cession_susecfull": "Cesión de cuenta realizada con éxito",
  "accounts": "Cuentas",
  "stratum": "Estrato",
  "position": "Posición",
  "id_card": "Cédula",
  "bill_upload": "Subir factura",
  "total files": "Total archivos",
  "difference": "Diferencia",
  "reconciled": "Conciliado",
  "notCancelled": "No cancelado",
  "number_of_agreement": "Número de acuerdo",
  "date_ipp_ipc_base": "Fecha IPP/IPC base",
  "contributing_rate": "Tasa de contribución",
  "collection_method": "Método de recolección",
  "help": "Ayuda",
  "rates_hepl_one": "Variables almacenadas con un símbolo Hash/Cerquilla #",
  "rates_hepl_two": "Deje un espacio antes de declarar una variable",
  "rates_hepl_three": "Separe cada dato con coma, por ejemplo: (#SMMLV + 30), (#SMMLV+30)*15",
  "rates_hepl_four": "Cuando se utiliza un operador ternario, recuerde usar '?' y luego ':' Ejemplo:",
  "rates_hepl_five": "Se utiliza para un '&&' y lo que se utiliza para un '||'",
  "rates_hepl_six": "En caso de que un operador ternario anidado no tenga la última validación, por favor establezca el valor en VERDADERO.",
  "public_lighting_fee": "Tarifa de alumbrado público",
  "timely_payment_date_budget": "Fecha de pago estimada presupuesto",
  "daily_number": "Número diario",
  "filter_date": "Filtrar por fecha",
  "ilumination_office": "Iluminación de oficina",
  "aires_office": "Aires de oficina",
  "ilumination_atm_office": "Iluminación ATM oficina",
  "aires_atm_office": "Aires ATM oficina",
  "aire_data_center": "Aires data center",
  "hydric": "Hídrico",
  "init_date": "Fecha incio",
  "end_date": "Fecha final",
  "add_investments": "Agregar inversiones",
  "base_lines": "Líneas base",
  "budgetFixed": "Presupuesto fijo",
  "remove_cession": "eliminar la cesión",
  "bot_upload_bill": "Bot sube factura",
  "implementation_upload_bill": "Implementación sube factura",
  "timely_payment_date": "Fecha de pago oportuno",
  "consumption": "Consumo",
  "image_bill": "Factura de imagen",
  "create_account": "Crear cuenta",
  "upload_bill": "Subir factura",
  "view_upload_bill_info": "Ver información de la factura subida",
  "account_relationship": "Relación de cuenta",
  "user_information": "Información del usuario",
  "add_guru_mode_message": "Para acceder a este módulo, debe agregar un modo de guruve a su perfil de usuario a través del siguiente enlace:",
  "message_template": "Plantilla de mensaje",
  "template_message_intro": "¡Bienvenido(a)! En este espacio, tendrás la oportunidad de elegir la plantilla perfecta para tu mensaje de bienvenida en WhatsApp.",
  "header": "Encabezado",
  "messages_lobby": "¡Te damos la bienvenida al formulario de creación de mensajes de Guruve! Aquí tendrás la oportunidad de configurar y personalizar tu mensaje a tu gusto.",
  "messages_lobby_1": "Selecciona y personaliza la plantilla de bienvenida para tu mensaje.",
  "messages_lobby_2": "Crea mensajes a tu medida.",
  "messages_lobby_3": "Interactúa con los usuarios del bot estableciendo condiciones de respuesta para los mensajes enviados.",
  "conditions_title": "Condiciones",
  "conditions_message_intro": "Aquí podrás establecer condiciones para los mensajes que envíes a través de Guruve, las cuales pueden ser utilizadas para validar mensajes, ofrecer opciones para que el usuario seleccione una alternativa u otra, y mucho más.",
  "conditions_message_example": "Estos son ejemplos de cómo utilizar una condición: $id_mensaje.value === 'Si' para consultar una edad, por ejemplo, o validar si el consumo de facturas es alto: $consumo_factura.value > '100000' o $age.value > '18', entre muchas otras consultas y validaciones.",
  "message_intro": "Personaliza tus mensajes a tu gusto para enviarlos a través de Guruve. Puedes enviar videos, imágenes y stickers.",
  "add_on_tree": "Agregar al árbol de mensajes",
  "dates_information": "Información de fechas",
  "origin_type": "Tipo de origen",
  "start_date": "Fecha de inicio",
  "destination_name": "Nombre del destino",
  "no_interactions": "Debes seleccionar una interacción para poder ver la información de los mensajes enviados.",
  "export_users_bill_upload": "Exportar facturas subidas por usuarios",
  "interactions": "Interacciones",
  "unreconciledTransactions": "Transacciones no conciliadas",
  "export_sent_messages": "Exportar mensajes enviados",
  "points": "Puntos",
  "carbon_print": "Huella de carbono",
  "meters_variation": "Variación de medidores",
  "energy": "Energía",
  "see_carbon_footprint": "Ver huella de carbono",
  "alerts": "Alertas",
  "total_active_energy": "Total energía activa",
  "total_reactive_energy": "Total energía reactiva",
  "average_consumption": "Consumo promedio",
  "actual_savings": "Ahorro real",
  "last_year": "Último año",
  "company_information": "Información compañía",
  "monthly_comparison": "Comparativa mensual",
  "values": "Valores",
  "energy_consumption": "Consumo energía",
  "reactive_power_consumption": "Consumo energía reactiva",
  "base_consumption": "Consumo base",
  "hide_accounts_without_data": "Ocultar cuentas sin datos",
  "flow_finished": "Flujo finalizado",
  "billUpload": "Carga de facturas",
  "massive_logbook": "Bitácora masiva",
  "provison_synlab_file": "Archivo de provisión Synlab",
  "brand": "Marca",
  "typeEquipment": "Tipo de equipo",
  "lightingType": "Tipo de iluminación",
  "inventory": "Inventario",
  "account_changes": "Cambios de cuenta",
  "mode_of_sale": "Modalidad de venta",
  "offer_number": "Número de oferta",
  "modality": "Modalidad",
  "investments": "Inversiones",
  "filter_base_consumption": "Filtrar por consumo base",
  "jump_message_intro": "Puedes usar los saltos para saltar a un mensaje específico usando los IDs de los mensajes.",
  "jump": "Salto",
  "vertebracanmakethepay": "Vertebra puede realizar el pago",
  "old_account_change_marketer": "El usuario [user_make_change] realizó un cambio de comercializadora del prestador [old_provider] al prestador [new_provider] de la cuenta [old_account] a la cuenta [new_account] con registro de XM [datexm] en la fecha [date]. El cambio está bajo la modalidad [modality] y la modalidad de venta es [sales_modality].",
  "new_account_change_marketer": "El usuario [user_make_change] generó el cambio de comercializadora de la cuenta [old_account] a la cuenta [new_account] con registro de Xm [datexm], la fecha de modificación es [date].",
  "document": "Documento",
  "projected": "Proyectado",
  "removeChangeMarketer": "Eliminar cambio",
  "not_registered": "No registrado",
  "change_date_to_regulatory_bodies": "Fecha de cambio frente a entes de control",
  "valve": "Válvula",
  "respel": "Respel",
  "internet": "Internet",
  "application": "Aplicación",
  "message_name": "Nombre del mensaje",
  "multiple_company_schedule": "Programación de múltiples compañías",
  "unbilled_month": "Mes no facturado",
  "invoice_account": "Cuenta de cobro",
  "withoutBiReport": "No tienes reportes Bi asignados para esta compañía",
  "see_childrens": "Ver hijas",
  "see_provider": "Ver prestador de servicio",
  "see_company": "Ver compañía",
  "parent_account": "Cuenta padre",
  "Scheduled": "Programado",
  "schedule_messages": "Programar mensajes",
  "extra_info": "Información adicional",
  "message_extra_info": "Agregar información adicional al mensaje",
  "error_validation": "Error de validación",
  "validation": "Validación",
  "startwith": "Inicia con",
  "endwith": "Termina con",
  "subtype": "Subtipo",
  "optional_info": "Información opcional",
  "message_optional_info": "Agregar información opcional al mensaje",
  "exeptionCommand_type": "Tipo de excepción",
  "exeptionCommandText": "Texto de excepción",
  "sendNext": "Enviar siguiente",
  "vertebra_can_pay": "Vertebra puede pagar",
  "type_place": "Tipo de lugar",
  "changeMarketer": "Cambio de comercializadora",
  "has_account_cession": "Cesión de cuenta",
  "novelty_account": "Novedad de cuenta",
  "office_code": "Código de oficina",
  "estimatePaymentDate": "Fecha estimada de pago",
  "new_marketer": "Nueva comercializadora",
  "withoutData": "Sin datos",
  "switchVerification": "Cambiar verificación",
  "withoutInformation": "Sin información",
  "street_lighting": "Alumbrado público",
  "cleanliness": "Aseo",
  "insertbillpetition": "Agregar petición de factura",
  "upload_enel_file": "Subir archivo Enel",
  "assigned_payer": "Pagador asignado",
  "estimate_payment_date": "Fecha estimada de pago",
  "initial_payment_date": "Fecha de pago inicial",
  "final_payment_date": "Fecha de pago final",
  "issue_date_config": "Configuración de fecha de emisión",
  "date_paid_config": "Configuración de fecha de pago",
  "billing_to": "Facturación",
  "iva_value": "Valor IVA",
  "telemetry_value": "Valor telemetría",
  "data_plan_vallue": "Valor plan de datos",
  "dateChange": "Fecha de cambio",
  "sell_modality": "Modalidad de venta",
  "spot_date": "Fecha pago de contado",
  "financing_date": "Fecha de financiación",
  "number_of_payments": "Número de pagos",
  "final_pay_date": "Fecha de pago final",
  "remove_change_marketer": "Eliminar cambio de comercializadora",
  "export_interactions": "Exportar interacciones",
  "export_unregistered_user_interactions": "Exportar interacciones usuarios no registrados",
  "discounted_call": "Llamada por descuento",
  "unregistered_users": "Usuarios no registrados",
  "meter_characterization": "Caracterización de medidor",
  "include_points_without_investments": "Incluir puntos sin inversiones",
  "include_points_without_inventory": "Incluir puntos sin inventario",
  "with": "Con",
  "without": "Sin",
  "error": "Error",
  "message_information": "Información del mensaje",
  "export_account": "Exportar cuentas",
  "week": "Semana",
  "budget_value": "Valor presupuestado",
  "budget_value_not_equal_total": "El valor presupuestado no es igual al total digitado",
  "budget_account_date": "Fecha presupuestada",
  "payment_immediate": "Pago inmediato",
  "diferent_values": "Valores diferentes",
  "configure_service_percentages": "Configurar los porcentajes de los servicios",
  "service_percentages_for_budget_calculation": "Porcentajes de los servicios para el cálculo del presupuesto",
  "market": "Mercado",
  "estimate_payment_config": "Configuración de estimación de pago",
  "export_bills_history": "Exportar historial de facturas",
  "bills_without_export": "Facturas sin exportar",
  "current": "Actual",
  "previous": "Anterior",
  "payment_today": "Pagar hoy",
  "expires_today": "Vencidas a hoy",
  "active_since": "Activo desde",
  "compare_budgets": "Comparar presupuestos",
  "indexer": "Indexador",
  "base_date": "Fecha base",
  "adequacy_value": "Valor adecuación",
  "equipment_value": "Valor equipos",
  "labor_cost_value": "Valor mano de obra",
  "exit_penalty_percentage": "Porcentaje penalización salida",
  "year": "Año",
  "weeks_configuration_json": "JSON de configuración semanas",
  "payer_mode": "Modo pagador",
  "reviewer_modo": "Modo revisor",
  "audit_mode": "Modo auditor",
  "bill_finder_mode": "Modo Buscador de facturas",
  "modes": "Modos",
  "recalculate_weeks": "Recalcular semanas",
  "last_bot_type": "Último tipo de bot",
  "dNoHave": "No tiene",
  "configure_uploader_permissions": "Configurar permisos uploader",
  "manage": "Gestionar",
  "icon": "Icono",
  "assing_massive_groups": "Asignar grupos masivamente",
  "queueBudgetManagement": "Gestión de presupuestos en cola",
  "remove_from_budget": "Eliminar del presupuesto",
  "out_budget": "Fuera de presupuesto",
  "in_budget": "En presupuesto",
  "roolback": "Rollback",
  "change_budget": "Cambiar presupuesto",
  "not_waiting": "No esperar",
  "bill_arrive": "Factura llega",
  "bill_not_arrive": "Factura no llega",
  "arrives": "Llega",
  "waiting": "Esperar",
  "is_external_pay": "Es pago externo",
  "deactivate_users": "Desactivar usuarios",
  "active_users": "Activar usuarios",
  "remenber": "Recuerde",
  "expires_at": "Vence en",
  "pin": "Pin",
  "link": "Link",
  "image": "Imagen",
  "duplicated_flow_end": "Duplicar - flujo Terminado",
  "duplicated_flow_not_end": "Duplicar - flujo no terminado",
  "section": "Sección",
  "extra": "Extra",
  "old_provider": "Prestador anterior",
  "new_provider": "Prestador nuevo",
  "old_account": "Cuenta anterior",
  "new_account": "Cuenta nueva",
  "registre_date": "Fecha de registro",
  "sales_modality": "Modalidad de venta",
  "change_marketer": "Cambio de comercializadora",
  "user_make_change": "Usuario que realiza el cambio",
  "market_type": "Tipo de mercado",
  "assigned_real_date": "Fecha real asignada",
  "no_reclassification": "No usar reclasificación",
  "guru_type": "Tipo de guruve",
  "define_template": "Definir plantilla",
  "guruve_mode": "Modo de guruve",
  "template": "Plantilla",
  "optional": "Opcional",
  "header_description": "Elige el tipo de contenido multimedia que usaras para el Encabezado de tu mensaje. (solo se puede elegir uno)",
  "header_type": "Tipo de encabezado",
  "none": "Ninguno",
  "video": "Video",
  "body": "Cuerpo del mensaje",
  "body_description": "Para personalizar el cuerpo del mensaje, introduzca el texto que desea mostrar. Puede utilizar variables para personalizar aún más el mensaje. Las variables se insertan de la siguiente manera: {{número de variable}}. Ejemplo: {{1}}. Asegúrese de asignar números de variable en orden ascendente para que el mensaje se muestre correctamente.",
  "characters": "Caracteres",
  "add_variable": "Añadir variable. Además, puede presionar la tecla # para desplegar un menú con diversas opciones.",
  "actions": "Acciones",
  "actions_description": "Define el tipo de acción que deseas mostrar cuando el usuario reciba el mensaje.",
  "invoice_search": "Búsqueda de facturas",
  "last_log": "Última bitácora",
  "comment_cannot_contain_quotes": "El comentario no puede contener comillas simples ni dobles",
  "add_log": "Agregar bitácora",
  "created_by": "Creado por",
  "log_today": "Bitácora hoy",
  "ipp_ipc_date_configuration": "Configuración de fecha IPP/IPC",
  "telephony": "Telefono",
  "television": "Televisión",
  "con_ocr": "Con OCR",
  "reason_user_charge": "Motivo carga usuario",
  "novelty_prevents_invoice_search": "Novedad impide búsqueda de factura",
  "inconsistent_charger": "Cargador incongruente",
  "manageCredentials": "Gestionar credenciales",
  "public_lighting_short": "A. Público",
  "bot_statistics": "Estadísticas de bots",
  "bots_executed": "Bots ejecutados",
  "exitosos": "Exitosos",
  "error_types": "Tipos de errores",
  "without_invoice": "Sin factura",
  "error_count": "Número de errores",
  "error_type": "Tipo de error",
  "emails": "Correos electrónicos",
  "generate_official_letter": "Generar oficio",
  "add_accounts": "Agregar cuentas",
  "remove_accounts": "Remover cuentas",
  "at_claiming": "En reclamación",
  "remove_claim": "Quitar reclamación",
  "open_invoice_history": "Abrir historial de facturas",
  "invoice_history": "Historial de facturas",
  "issue_date": "Fecha de emisión",
  "suspension_date": "Fecha de suspensión",
  "no_invoice_selected": "No se ha seleccionado una factura",
  "created_by_bulk_logs": "Creada por bitácoras masivas",
  "full_company_name": "Nombre completo de la empresa",
  "all_chargers": "Todos los cargadores",
  "guilty": "Culpable",
  "page_error": "Error página",
  "in_process": "En proceso",
  "total_attempts": "Total intentos",
  "view_all_details": "Ver todos los detalles",
  "completeds": "Completados",
  "created_provider_ticket": "Creó ticket al prestador",
  "file_number": "Número de radicado",
  "created_from": "Creado desde",
  "updateNoveltyAccount": "Editar novedad de cuenta",
  "consumption_calculation": "Cálculo consumos",
  "unit_cost": "Costo unitario",
  "tariff_recovery_cost": "Costo de recuperación tarifaria",
  "view_bills": "Ver facturas",
  "uploader_error": "Archivo con errores",
  "uploader_error_subtitle": "Por favor, verifica y modifica la información del archivo antes de volver a subirlo",
  "uploader_error_description": "El contenido que has enviado tiene los siguientes errores",
  "uploader_redirect_dashboard": "Volver al inicio",
  "status_report": "Reporte de estado",
  "upload_massive_file": "Subir archivo masivo",
  "is_multiple": "Es múltiple",
  "provider_inactive_since": "Este prestador se encuentra inactivo desde el",
  "selected_point": "Punto seleccionado",
  "not_selected": "No seleccionado",
  "zip_conditions": "Para cargar los datos correctamente, asegúrate de que: \n* Los archivos en el ZIP coincidan con el formato de la plantilla. \n* Los archivos PDF tengan el formato indicativo_pais-numero_telefono.formato. \nejemplo: 573XXXXXXXXX.pdf",
  "donwloadZip": "Descargar ZIP",
  "payment_information": "Información de pago",
  "searching_information": "Información de búsqueda",
  "visitado": "Visitado",
  "edit_investments": "Editar inversiones",
  "total_rows": "Total de filas",
  "total_ok": "Total OK",
  "total_error": "Total error",
  "last_update": "Ultima actualización",
  "job_id": "ID del trabajo",
  "entity": "Entidad",
  "proccess_and_ignore_errors": "Procesar e ignorar errores",
  "this_process_can_take_a_while": "Este proceso puede tardar un tiempo",
  "download_report": "Descargar reporte",
  "display_errors": "Mostrar errores",
  "eguru": "Eguru",
  "additional_days": "Días adicionales",
  "add_issue": "Agregar novedad",
  "account_issue": "Novedad de cuenta",
  "invoice_issue": "Novedad de factura",
  "internal_use_issue_vertebra": "Novedad de uso interno Vertebra",
  "budget_issue": "Novedad en presupuesto",
  "closed_account_issue": "Novedad de cuenta cerrada",
  "change_bill": "Cambio de factura",
  "invoice_value_affecting_account_issue": "Novedad de cuenta que afecta el valor de la factura",
  "id_parent": "ID padre",
  "updated_at": "Actualizado",
  "total_cargada": "Total cargadas",
  "total_digitadas": "Total digitadas",
  "total_no_facturadas": "Total no facturadas",
  "increase_alerts": "Alertas de aumento",
  "decrease_alerts": "Alertas de disminución",
  "average_alerts": "Alertas en promedio",
  "total_alerts": "Total alertas",
  "view_alerts": "Ver alertas",
  "alert_type": "Tipo de alerta",
  "alert_status": "Estado de alerta",
  "alert_month": "Mes de alerta",
  "current_consumption": "Consumo actual",
  "comparison_consumption": "Consumo comparación",
  "variation": "Variación",
  "return_to_average": "Retorno al promedio",
  "last_year_consumption": "Consumo del último año",
  "alerts_chart": "Consumo del año",
  "perform_tracking": "Realizar seguimiento",
  "ticket": "Ticket",
  "registration_successful": "Registro realizado con éxito",
  "mark_as_not_real": "Marcar como no real",
  "mark_as_not_real_description": "La alerta se marcará como no real y dejará de aparecer",
  "marked_as_not_real": "Marcada como no real",
  "is_real": "Es real",
  "digitation_error": "Error de digitación",
  "new_status": "Nuevo estado",
  "file_will_arrive_soon": "El archivo llegará en unos minutos al correo",
  "revert_closed": "Revertir cerrado",
  "confirm_revert_status": "¿Estás seguro de revertir el estado de esta alerta?",
  "last_tracking_date": "Fecha del último seguimiento",
  "showing": "Mostrando",
  "hiding": "Ocultando",
  "parent_accounts": "Cuentas padres",
  "child_accounts": "Cuentas hijas",
  "other_accounts": "Otras cuentas",
  "parent_and_child_accounts": "Cuentas padres e hijas",
  "accounts_excluding_children": "Cuentas excluyendo hijas",
  "accounts_excluding_parents": "Cuentas excluyendo padres",
  "indifferent": "Indiferente",
  "all_companies": "Todas las compañías",
  "load_another_bill": "Cargar otra factura",
  "view_meter_alerts": "Ver alertas de medidores",
  "meter_alerts": "Alertas de medidores",
  "increase": "Aumento",
  "decrease": "Disminución",
  "net_balance": "Balance neto",
  "maximum": "Máximo",
  "optimal_maximum": "Máximo óptimo",
  "average": "Media",
  "minimum": "Mínimo",
  "optimal_minimum": "Mínimo óptimo",
  "chart": "Gráfica",
  "view_full_screen": "Ver pantalla completa",
  "last": "Último",
  "tracking": "Seguimiento",
  "parent_company": "Compañía padre",
  "next": "Siguiente",
  "mark_as_not_real_question": "¿Estás seguro de marcar esta alerta como no real? Esto también la cerrará",
  "comparison": "Comparación",
  "average_1_year": "Promedio 1 año",
  "average_6_months": "Promedio 6 meses",
  "insertFilePlaneClaro": "Subir archivo plano Claro",
  "parents_only": "Solo padres",
  "add_filters": "Agregar filtros",
  "remove_filter": "Eliminar filtro",
  "filter_limit_reached": "Límite de filtros alcanzado",
  "save_filter_settings": "Guardar configuración de filtros",
  "addUserCredentials": "Agregar credenciales de usuario",
  "save_filter_prompt": "Si aceptas se guardará la configuración de filtros actual. Cada que abras este módulo por primera vez se cargará esta configuración",
  "ignore_atypical_consumption": "Ignorar consumo atípico",
  "view_tracking": "Ver seguimiento",
  "scheduled_start": "Inicio programado",
  "scheduled_end": "Fin programado",
  "total_lesson": "Total de lecciones",
  "progress": "Progreso",
  "eguru_courses": "Cursos de eguru",
  "instances": "Instancias",
  "category": "Categoría",
  "course_setup": "Configuración del curso",
  "complete_all_fields": "Completa todos los campos",
  "customize_your_course": "Personaliza tu curso",
  "lessons": "Lecciones",
  "course_name": "Nombre del curso",
  "course_description": "Descripción del curso",
  "course_image": "Imagen del curso",
  "course_chapters": "Capítulos del curso",
  "drag_and_drop_to_reorder_chapters": "Arrastra y suelta para reordenar los capítulos",
  "active_course": "Curso activo",
  "no_chapters": "No hay capítulos",
  "add_module": "Agregar módulo",
  "module_name": "Nombre del módulo",
  "add_module_name": "Agregar nombre del módulo",
  "add_chapter": "Agregar capítulo",
  "compliance": "Cumplimiento",
  "civil_liability": "Responsabilidad civil",
  "offer_sincerity": "Seriedad de oferta",
  "insurance_policy": "Póliza de seguro",
  "subservices": "Subservicios",
  "total_lessons": "Total de lecciones",
  "contend": "Contenido",
  "datail": "Detalle",
  "last_access": "Último acceso",
  "instance_status": "Estado de la instancia",
  "on_progress": "En progreso",
  "previous_status": "Estado anterior",
  "deactivate_account": "Inactivar cuenta",
  "account_deactivated_successfully": "La cuenta se ha desactivado exitosamente",
  "active_period": "Periodo activo",
  "case_title": "Título para el caso",
  "create_odoo_ticket": "Crear ticket de Odoo",
  "configure_periods": "Configurar periodos",
  "add_date_range": "Agregar rango de fechas",
  "view_comments": "Ver comentarios",
  "total_completed": "Total completadas",
  "in_progress": "En progreso",
  "comments_instance": "Comentarios de la instancia",
  "total_comments": "Total de comentarios",
  "reload": "Recargar",
  "comments_description": "En esta sección encontrarás los comentarios de cada lección de la instancia. Si alguna lección no cuenta con comentarios, no aparecerá nada para mostrar.",
  "thermal_energy": "Energía térmica",
  "used_massive_log": "Se usó bitácora masiva",
  "measurement_location": "Lugar de medición",
  "is_completed": "Está completado",
  "lesson": "Lección",
  "contend_time": "Tiempo de contenido",
  "id_video": "ID del video",
  "upload_video": "Subir video",
  "total_pages": "Total de páginas",
  "add_lesson": "Agregar lección",
  "courses": "Cursos",
  "max_characters_allowed": "Máximo {{charactersAllowed}} caracteres permitidos",
  "successful_export": "Exportación completa",
  "info_will_arrive_in_minutes_to_email": "La información llegará en unos minutos al correo {{email}}",
  "total_reviewed": "Total revisado",
  "total_no_reviewed": "Total no revisado",
  "no_reviewed_days": "Días sin revisar",
  "not_review_in_a_last_two_days": "No revisado en los últimos dos días",
  "subject": "Asunto",
  "from_email": "De correo",
  "has_attachment": "Tiene adjunto",
  "attachment": "Adjunto",
  "is_reviewed": "Revisado",
  "from": "De",
  "to": "Para",
  "to_me": "Para mí",
  "replay_to": "Responder a",
  "bot_reviewed": "Revidada por bot",
  "comment": "Comentario",
  "remove_review": "Eliminar revisión",
  "edit_review": "Editar revisión",
  "no_reviewed_day": "Días sin revisar",
  "bot_not_reviewed": "No revidada por bot",
  "total_lessons_completed": "Total de lecciones completadas",
  "add_lessons_to_save": "Agrega lecciones para guardar el curso",
  "searchBills": "Buscar facturas",
  "bots_execution": "Ejecución bots",
  "export_policy": "Exportar póliza",
  "max_budget": "Presupuesto máximo",
  "max_value": "Valor máximo",
  "digital_status": "Estado digital",
  "total_accounts": "Total de cuentas",
  "total_payments": "Total de pagos",
  "execute_accounts_in_this_cluster": "Ejecutar cuentas de este cluster",
  "image_upload_flag": "La imagen no es obligatoria en este modo. Si se carga una imagen, solo aparecerá en el encabezado del texto.",
  "upload_attachment": "Subir adjunto",
  "tags": "Etiquetas",
  "include_policy": "Incluir póliza",
  "export_only_policy": "Exportar sólo póliza",
  "attachments": "Adjuntos",
  "more": "Más",
  "total_users": "Total de usuarios",
  "downloaded_files": "Archivos descargados",
  "file_info": "Información del archivo",
  "file_name": "Nombre del archivo",
  "size_file": "Tamaño del archivo",
  "upload_bill_description_one": "Obtén una copia de la factura que deseas cargar a la cuenta",
  "upload_bill_description_two": "Verifique los detalles de la cuenta a la que se cargará la factura, incluyendo el número de cuenta, la empresa y otros datos.",
  "account_info": "Información de la cuenta",
  "see_last_bill": "Ver la última factura",
  "last_bill": "Última factura",
  "automatically_upload": "Cargar automáticamente",
  "upload_date": "Fecha de carga",
  "see_upload_bills_view": "Ver facturas cargadas",
  "see_bill": "Ver factura",
  "forum": "Requiere comentario para completar lección",
  "report_generated_successfully": "Reporte generado correctamente",
  "executions_last_60_min": "Ejecuciones últimos 60 min",
  "available_executions": "Ejecuciones disponibles",
  "go_back": "Volver",
  "perform_new_execution": "Realizar nueva ejecución",
  "end": "Fin",
  "execute": "Ejecutar",
  "select_accounts": "Seleccionar las cuentas",
  "selected": "Seleccionadas",
  "are_you_sure_to_select": "Estás seguro de seleccionar",
  "bot_executed": "Bot ejecutado",
  "cancel_execution": "Cancelar ejecución",
  "without_cluster": "Sin cluster",
  "execute_cluster_and_without_cluster": "Ejecutar cluster {{cluster}} y los que no tienen cluster",
  "remove_selection": "Eliminar selección",
  "last_execution": "Última ejecución",
  "account_change": "Cambio de cuenta",
  "massive_novelties": "Novedades masivas",
  "close_novelties": "Cerrar novedades",
  "close_massive_novelties_confirm": "Si aceptas todas las novedades masivas de esta ejecución se cerrarán",
  "add_massive_comments": "Agregar comentario masivo",
  "invoice_uploader": "Cargador de facturas",
  "created_by_cluster": "Creado por cluster",
  "invoices_not_uploaded_this_month": "Facturas sin cargar este mes",
  "urgent": "Urgente",
  "are_you_sure": "¿Estás seguro?",
  "if_chose_search_bills_tab_you_lose_email_filters": "Si eliges la pestaña de búsqueda de facturas, perderás los filtros de correos",
  "addDocuments": "Agregar documentos",
  "document_type": "Tipo de documento",
  "view_document": "Ver documento",
  "no_data": "Sin datos",
  "add_document": "Agregar documento",
  "legal_name": "Nombre legal",
  "nit": "NIT",
  "document_expiration_date": "Fecha de expiración del documento",
  "operation_type": "Tipo de operación",
  "see_actual_bill": "Ver factura actual",
  "bill_already_uploaded": "Factura ya cargada",
  "bill_already_uploaded_description": "La factura ya se ha cargado a la cuenta",
  "last_invoice_uploaded": "Última factura cargada",
  "add_item": "Agregar item",
  "hide_senders": "Ocultar remitentes",
  "hide_senders_prompt": "Aquí puede ocultar correos de remitentes. Agregue la dirección de correo electrónico que desea excluir a la lista.",
  "clear_filters": "Limpiar filtros",
  "stats": "Estadísticas",
  "odoo_ticket_id": "ID del ticket Odoo",
  "ticket_type": "Tipo de ticket",
  "model": "Modelo",
  "success_message": "La lista de correos ocultos se ha actualizado",
  "review_result": "Resultado de revisión",
  "order_by": "Ordenar por",
  "review_duplicate_bill": "Revisar factura duplicada",
  "non_billing_email": "Correo no es de facturación",
  "child": "Hijo",
  "id": "ID",
  "attachments_type": "Tipo de adjuntos",
  "ticket_code": "Código de ticket",
  "add_all_the_accounts": "Agregar todas las cuentas",
  "add_all_the_accounts_confirm": "Si aceptas se agragarán todas las cuentas que cumplen con estos filtros",
  "invoice_fields": "Campos facturas",
  "no_emails_selected": "No hay correos seleccionados",
  "select_emails_to_view": "Selecciona los correos para ver",
  "no_emails_data": "No hay datos de correos",
  "inactive_account": "Cuenta inactiva",
  "daily": "Diario",
  "weekly": "Semanal",
  "monthly": "Mensual",
  "custom": "Personalizado",
  "today": "Hoy",
  "TwoDays": "Dos días",
  "SevenDays": "Siete días",
  "30Days": "30 días",
  "non_existent": "No existente",
  "status_previously_listed": "Ya se encuentra cargado",
  "the_bill_will_upload_in_the_account": "Se cargará la factura en las cuentas",
  "multiple_bill_upload": "Multiple carga de factura",
  "bill_uploaded": "Factura cargada",
  "the_bill_should_be_uploaded_from_the_main_account": "La factura se debe cargar desde la cuenta principal",
  "the_bill_should_be_pay_from_the_main_account": "La factura se debe pagar desde la cuenta principal",
  "block_subject": "Asunto de bloqueo",
  "block_email": "Correo bloqueado",
  "add_hidden_item": "Agregar a la lista de bloqueados",
  "empty_hidden_fields": "Por favor, agregue al menos un elemento oculto",
  "other_concepts": "Otros conceptos",
  "credit_note": "Nota de crédito",
  "bots_will_be_executed_for": "Se ejecutarán bots para",
  "original_sender": "Remitente original",
  "of_them_will_run_as_doubles": "de ellas se ejecutarán como dobles",
  "clean": "Limpiar",
  "visit_date": "Fecha de visita",
  "orden": "Orden",
  "error_subtitle": "¡Ups! Se ha producido un error",
  "error_description": "Se ha producido un error al cargar los datos. Por favor, inténtelo de nuevo más tarde.",
  "visit_count": "Conteo de visitas",
  "initial_balance": "Saldo inicial",
  "company_assignment": "Asignación de compañía",
  "company_assigned_successfully": "Compañía asignada correctamente",
  "success": "Éxito",
  "accounts_petitions": "Petición de cuenta",
  "company_name": "Nombre de la empresa",
  "marketer_name": "Nombre del comercializador",
  "marketer_email": "Correo electrónico del comercializador",
  "has_bill": "Tiene factura",
  "first_send": "Primer envío",
  "retries": "Reintentos",
  "last_attempt": "Último intento",
  "uploaders": "Cargadores",
  "details": "Detalles",
  "open_extras": "Extras abiertos",
  "one_month_comparation": "Comparación de un mes",
  "one_month_variation": "Variación de un mes",
  "one_month_variation_percent": "Variación de un mes (%)",
  "three_months_comparation": "Comparación de tres meses",
  "three_months_variation": "Variación de tres meses",
  "three_months_variation_percent": "Variación de tres meses (%)",
  "six_months_comparation": "Comparación de seis meses",
  "six_months_variation": "Variación de seis meses",
  "six_months_variation_percent": "Variación de seis meses (%)",
  "annual_comparation": "Comparación anual",
  "annual_variation": "Variación anual",
  "annual_variation_percent": "Variación anual (%)",
  "alert_value_percent_max_limit_message": "El valor actual supera el {{percent}}% de variacion respecto al {{comparison}}",
  "annual_comparison": "Año anterior",
  "one_month_comparison": "Mes anterior",
  "six_months_comparison": "De hace 6 meses",
  "three_months_comparison": "De hace 3 meses",
  "triggered_alerts": "Alertas disparadas",
  "details_of": "Detalles de",
  "status_last_followup": "Estado ult. seguimiento",
  "pending": "Pendiente",
  "managed": "Gestionado",
  "bill_alerts": "Alertas de facturas",
  "time_elapsed": "Tiempo transcurrido",
  "digitation_role": "Rol de digitación",
  "select_digitation_role": "Seleccione un rol de digitación",
  "current_value": "Valor actual",
  "seccess": "Éxito",
  "operationSuccessful": "Operación exitosa",
  "schedule_message": "Mensaje programado",
  "schedule_message_placeholder": "Escribe tu mensaje aquí",
  "add_message": "Agregar mensaje",
  "add_a_list_of_messages": "Para agregar una lista de mensajes o un mensaje único, haga clic en el icono + en la parte superior derecha de la pantalla.",
  "bots_with_very_soon_execution": "Bots con ejecución muy pronta",
  "issue_extra_days": "Días extra de ejecución",
  "tv_telephony": "TV y Telefonía",
  "no_bill_selected": "No se ha seleccionado ninguna factura",
  "select_bill_to_review": "Por favor, selecciona una factura para revisar",
  "date_begin": "Fecha de inicio",
  "date_end": "Fecha final",
  "date_bill_uploaded": "Fecha de carga de la factura",
  "extra_days": "Días extra",
  "days": "días",
  "days_of_execution": "Días de ejecución",
  "extra_days_of_execution": "Días extra de ejecución",
  "select_one_day": "Selecciona un día",
  "select_extra_days": "Selecciona días extra",
  "select_days_of_execution": "Selecciona días de ejecución",
  "select_extra_days_of_execution": "Selecciona días extra de ejecución",
  "reviewed_at": "Revisado",
  "assing_many_reviews_warning": "Esta acción revisara todas las facturas con los filtros selecccionados. ¿Estás seguro de que deseas continuar?",
  "max_amount": "Monto máximo",
  "child_account": "Cuenta hija",
  "show_parents": "Mostrar padres",
  "show_childs": "Mostrar hijos",
  "overdue_payment": "Pago vencido",
  "is_claiming": "Es reclamación",
  "service_name": "Nombre del servicio",
  "select_record_the_change": "Seleccione un registro para ver los cambios",
  "user_makinguthe_change": "Usuario que realiza el cambio",
  "operation_date": "Fecha de operación",
  "review_many_bills": "Revisar muchas facturas",
  "user_in_charge": "Usuario a cargo",
  "date_filing": "Fecha de expedición",
  "expiration_date": "Fecha de vencimiento",
  "no_documents": "No hay documentos",
  "upload_excel": "Cargar excel",
  "collection_office": "Oficina de recaudo",
  "originator_nit": "Nit originador",
  "check_value": "Valor cheque",
  "bank_movements": "Movimientos bancarios",
  "upload_bank_movements": "Cargar movimientos bancarios",
  "are_you_sure_to_upload_this_file": "¿Estás seguro de cargar este archivo?",
  "product": "Producto",
  "initial_and_final_payment_dates": "Fechas de pago inicial y final",
  "automatic_review": "Revisado automáticamente",
  "accountProvider": "Proveedor de cuenta",
  "pressEnterOrComma": "Presiona Enter o coma",
  "view_bank_movements": "Ver movimientos bancarios",
  "noOCR": "Sin OCR",
  "add_new_blocked_category": "Agregar nueva categoría bloqueada",
  "blocked_categories_blacklist": "Categorías bloqueadas (Lista negra)",
  "add_new_enabled_category": "Agregar nueva categoría habilitada",
  "enabled_categories_whitelist": "Categorías habilitadas (Lista blanca)",
  "category_added_successfully": "Categoría agregada correctamente",
  "category_removed_successfully": "Categoría eliminada correctamente",
  "error_removing_category": "Error al eliminar la categoría",
  "please_enter_a_category_name": "Por favor, ingresa un nombre de categoría",
  "this_category_already_exists_in_the_destination_list": "Esta categoría ya existe en la lista de destino",
  "category_moved_successfully": "Categoría movida correctamente",
  "configure_white_and_blacklist": "Configurar lista blanca y lista negra",
  "temp_pay": "Pago temporal",
  "no_invoice_number": "Sin número de factura",
  "poliza_columns": "Columnas poliza",
  "export_bills_poliza": "Exportar facturas poliza",
  "payment_blocked": "Pago bloqueado",
  "payment_blocked_description": "El pago sera bloqueado por el usuario",
  "download_relation_export": "Descargar reporte de relación",
  "paymentRelation": "Relación de pago",
  "remove_temp_pay": "Eliminar pago temporal",
  "transaction_in_verification": "Transacción en verificación",
  "validate_transaction": "Validar transacción",
  "range_cannot_exceed_one_month": "El rango no puede ser mayor a un mes",
  "reconciliation_report": "Reporte conciliaciones"
}
